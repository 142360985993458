<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#E20000" fill-rule="evenodd" d="M9 14a.48.48 0 0 0 .352-.148.48.48 0 0 0 .148-.352v-6a.48.48 0 0 0-.148-.352A.48.48 0 0 0 9 7a.48.48 0 0 0-.352.148.48.48 0 0 0-.148.352v6c0 .135.05.253.148.352A.48.48 0 0 0 9 14zm-5 0a.48.48 0 0 0 .352-.148.48.48 0 0 0 .148-.352v-6a.48.48 0 0 0-.148-.352A.48.48 0 0 0 4 7a.48.48 0 0 0-.352.148.48.48 0 0 0-.148.352v6c0 .135.05.253.148.352A.48.48 0 0 0 4 14zm8-12H9V1a.961.961 0 0 0-.297-.703A.961.961 0 0 0 8 0H5a.961.961 0 0 0-.703.297A.961.961 0 0 0 4 1v1H1a.961.961 0 0 0-.703.297A.961.961 0 0 0 0 3v1c0 .27.099.505.297.703A.961.961 0 0 0 1 5v9c0 .552.195 1.023.586 1.414.39.39.862.586 1.414.586h7c.552 0 1.023-.195 1.414-.586.39-.39.586-.862.586-1.414V5c.27 0 .505-.099.703-.297A.961.961 0 0 0 13 4V3a.961.961 0 0 0-.297-.703A.961.961 0 0 0 12 2zm-7-.5a.48.48 0 0 1 .148-.352A.48.48 0 0 1 5.5 1h2a.48.48 0 0 1 .352.148A.48.48 0 0 1 8 1.5V2H5v-.5zM11 14c0 .27-.099.505-.297.703A.961.961 0 0 1 10 15H3a.961.961 0 0 1-.703-.297A.961.961 0 0 1 2 14V5h9v9zm.5-10h-10a.48.48 0 0 1-.352-.148A.48.48 0 0 1 1 3.5a.48.48 0 0 1 .148-.352A.48.48 0 0 1 1.5 3h10a.48.48 0 0 1 .352.148A.48.48 0 0 1 12 3.5a.48.48 0 0 1-.148.352A.48.48 0 0 1 11.5 4zm-5 10a.48.48 0 0 0 .352-.148A.48.48 0 0 0 7 13.5v-6a.48.48 0 0 0-.148-.352A.48.48 0 0 0 6.5 7a.48.48 0 0 0-.352.148A.48.48 0 0 0 6 7.5v6c0 .135.05.253.148.352A.48.48 0 0 0 6.5 14z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-remove',
};
</script>
