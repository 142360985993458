<template>
  <li class="form-checkbox">
    <input
      type="checkbox"
      :name="name"
      v-bind:checked="value"
      v-on:input="$emit('input', $event.target.value)"
    >
    <span class="form-checkbox__icon"></span>
    <label class="form-checkbox__label" for="checkbox-option-1" v-html="label"></label>
  </li>
</template>

<script>

export default {
  name: 'input-checkbox',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
    },
    error: {
      type: String,
    },
  },
};
</script>
