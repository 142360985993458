<template>
  <div class="column is-3-desktop is-offset-1-desktop">
    <div class="sidebar-container" :class="{ 'nav-active': navActive }">
      <button
        class="sidebar-toggle is-hidden-desktop"
        :class="{ 'is-active': sidebarExpanded }"
        title="Expand child pages navigation"
        aria-controls="child-pages-navigation"
        :aria-expanded="ariaExpanded"
        @click="toggleSidebar"
      >
        <div class="sidebar-toggle__wrap">
          <span class="is-regular has-text-weight-bold">Jump To</span>
          <span aria-hidden="true" class="icon icon--arrow-wide">
            <svg-arrow-wide></svg-arrow-wide>
          </span>
        </div>
      </button>
      <div class="sidebar-menu__header">
        <div class="sidebar-menu__wrap">
          <span class="sidebar-menu__link">Jump to</span>
        </div>
      </div>
      <ul
        class="sidebar-menu"
        :class="{ 'is-expanded': sidebarExpanded }"
        id="child-pages-navigation"
      >
        <li class="sidebar-menu__item" v-for="section in sections" :key="section.id">
          <div class="sidebar-menu__wrap">
            <a
              :ref="section.slug"
              v-smooth-scroll="{ duration: 800, offset: -50 }"
              class="sidebar-menu__link is-active"
              tabindex="0"
              @click="openSection(section.slug)"
              :href="`#${section.slug}`"
            >{{ section.title }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SvgArrowWide from '@/svgs/ArrowWide.vue';

export default {
  name: 'expandable-section-sidebar',
  props: {
    sections: {
      type: Array, // of Objects
      required: true,
    },
  },
  components: { SvgArrowWide },
  data() {
    return {
      sidebarExpanded: false,
    };
  },
  computed: {
    ...mapState({
      navActive: state => state.menu.navActive,
    }),
    ariaExpanded() {
      return this.sidebarExpanded ? 'true' : 'false';
    },
  },
  methods: {
    /**
     * Tell the store to set the chosen section as active
     */
    openSection(slug) {
      this.$store.commit('expandableSections/setActiveSection', slug);
      this.toggleSidebar();
    },
    /**
     * Expand the sidebar on mobile views
     */
    toggleSidebar() {
      this.sidebarExpanded = !this.sidebarExpanded;
    },
  },
  mounted() {
    /**
     * Check for location hash and expand relevant section
     */
    const { hash } = window.location;
    const slug = hash.slice(1);
    if (hash) {
      this.$refs[slug][0].click();
      this.$store.commit('expandableSections/setActiveSection', slug);
    }
  },
};
</script>
