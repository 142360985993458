<template>
  <div v-click-outside="hide" class="form-item">
    <label v-if="label" class="form-label">{{ label }}</label>
    <p v-if="description" class="form-description">{{ description }}</p>
    <div class="form-multiselect">
      <button
        class="form-multiselect__toggle"
        :class="{ 'is-active' : expanded }"
        title="expand multiselect label options"
        :aria-expanded="expanded"
        aria-labelledby="multiselect-label"
        aria-describedby="multiselect-label-alert"
        aria-controls="multiselect-options"
        @click="toggle"
      >
        <span class="form-multiselect__toggle__text">{{ dropdownText }}</span>
      </button>

      <fieldset v-if="expanded" class="form-multiselect__fieldset">
        <ul class="form-multiselect__options">
          <li v-for="item in items" :key="item.id" class="form-radio">
            <input
              type="radio"
              :id="item.name"
              :name="item.name"
              v-bind:checked="item.active"
              @input="check(label, item.id)"
            >
            <span class="form-radio__icon"></span>
            <label class="form-radio__label" :for="item.name">{{ item.name }}</label>
          </li>
        </ul>
      </fieldset>
    </div>
    <span class="form-alert" aria-live="assertive" role="alert"></span>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    defaultValue: {
      type: String,
      default: 'Please select',
    },
    items: {
      type: Array,
      required: true,
    },
    performancesPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    check(filterCategory, filterId) {
      if (this.performancesPage) {
        this.$store.dispatch('eventCalendar/updateFilter', { filterCategory, filterId });
      } else {
        this.$store.dispatch('eventFilter/updateFilter', { filterCategory, filterId });
      }
    },
    toggle() {
      this.expanded = !this.expanded;
    },
    hide() {
      this.expanded = false;
    },
  },
  computed: {
    activeItems() {
      return this.items.filter(option => option.active);
    },
    dropdownText() {
      if (this.activeItems.length) {
        /* eslint-disable-next-line arrow-body-style */
        return this.activeItems.reduce((previous, current, idx) => {
          return idx === 0 ? current.name : `${previous}, ${current.name}`;
        }, '');
      }
      return this.defaultValue;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
