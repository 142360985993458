<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" fill-rule="evenodd" d="M19.717 7.479c.189.113.283.302.283.566v13.201c0 .491-.246.737-.737.737h-5.439c-.453 0-.68-.246-.68-.737v-8.102H6.856v8.102c0 .491-.227.737-.68.737H.736c-.49 0-.736-.246-.736-.737v-13.2c0-.265.094-.454.283-.567L9.405.17c.302-.227.604-.227.907 0l9.405 7.309z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-home',
};
</script>
