var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column is-5-desktop is-4-widescreen"},[_c('div',{staticClass:"event-calendar__sidebar has-background-blue-tint-lighter"},[_c('div',{staticClass:"event-calendar__sidebar__container"},[_c('toggle-view'),_c('h3',{staticClass:"has-text-blue has-text-weight-bold"},[_vm._v("Select a date")]),_c('v-date-picker',{staticClass:"is-hidden-touch",attrs:{"is-expanded":"","is-inline":"","value":_vm.date,"attributes":[
              {
                key: 'selected day',
                highlight: true,
                dates: _vm.date,
              } ],"min-date":new Date()},on:{"input":_vm.updateDate}}),_c('v-date-picker',{staticClass:"is-hidden-desktop",attrs:{"is-expanded":"","value":_vm.date,"attributes":[
              {
                key: 'selected day',
                highlight: true,
                dates: _vm.date,
              } ],"min-date":new Date()},on:{"input":_vm.updateDate}},[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"form-upload"},[_c('button',{staticClass:"form-upload__button"},[_c('span',{staticClass:"form-upload__text"},[_vm._v(_vm._s(_vm.dateFormatted))]),_c('span',{staticClass:"icon icon--upload"},[_c('svg-calendar')],1)])])])]),_c('dropdown-button',{attrs:{"label":"Filter results","modal":true,"activeFilters":_vm.selectedFiltersCount},on:{"click":_vm.toggleFilterModal}})],1)]),(_vm.filterModalActive)?_c('dropdown-modal',{attrs:{"filters":_vm.filters,"performancesPage":true},on:{"toggleModal":_vm.toggleFilterModal}}):_vm._e(),(_vm.selectedFiltersCount)?_c('div',{staticClass:"event-calendar__sidebar__pills"},[_c('event-filter-pill',{attrs:{"filter":{
            optionId: 0,
            optionsName: 'Clear all filters'
          },"performancesPage":true}}),_vm._l((_vm.selectedFilters),function(filter){return _c('event-filter-pill',{key:filter.id,attrs:{"filter":filter,"performancesPage":true}})})],2):_vm._e()],1),_c('performance-list')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }