<template>
  <div class="has-text-centered event-filter__search has-background-blue-tint-lighter">
    <div class="container">
      <h4 class="title is-4 is-family-extended has-text-weight-medium has-text-blue">
        Find an event
      </h4>
      <div class="field">
        <div class="control">
          <input
            type="text"
            class="input is-large"
            :value="searchText"
            placeholder="Search event names, genres or venues..."
            @input="setSearchTextInput"
            @keyup.enter="submitSearch"
          >
          <div class="event-filter__search__icons">
            <div
              v-if="searchText"
              @click="clearSearchText"
              class="icon icon--close">
              <svg-close></svg-close>
            </div>
            <div
              v-else
              class="icon icon--search">
              <svg-search></svg-search>
            </div>
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as queryString from 'query-string';

import SvgSearch from '@/svgs/Search.vue';
import SvgClose from '@/svgs/Close.vue';

export default {
  name: 'event-filter-search',
  props: {
    allEventsPageUrl: String,
  },
  components: {
    SvgSearch,
    SvgClose,
  },
  mounted() {
    const parsedQueryString = queryString.parse(window.location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
    });
    if (parsedQueryString.search) {
      this.setSearchText(parsedQueryString.search);
    }
  },
  computed: {
    ...mapState({
      searchText: state => state.eventFilter.searchText,
    }),
  },
  methods: {
    setSearchText(text) {
      this.$store.dispatch('eventFilter/updateTextSearchResult', text);
    },
    setSearchTextInput(event) {
      this.$store.dispatch('eventFilter/updateTextSearchResult', event.target.value);
    },
    clearSearchText() {
      this.$store.dispatch('eventFilter/updateTextSearchResult', '');
    },
    submitSearch() {
      if (this.allEventsPageUrl) {
        window.location = `${this.allEventsPageUrl}?search=${this.searchText}`;
      }
    },
  },
};
</script>
