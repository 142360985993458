<template>
  <div class="general-enquiry">
    <input-radio
      name="enquiry-type"
      label="I'd like to*"
      labelId="enquiry-type"
      :radioFields="radioFields"
      v-validate="'required'"
      v-model="enquiryType"
      @input="updateType"
      :has-error="errors.has('enquiry-type')"
      :error="errors.first('enquiry-type')"
      aria-label="enquiry-type"
    ></input-radio>
    <input-text
      name="name"
      label="Name *"
      v-model="name"
      @input="updateName"
      v-validate="'required'"
      :has-error="errors.has('name')"
      :error="errors.first('name')"
      ariaLabel="general-name"
    ></input-text>
    <input-text
      name="email"
      label="Email *"
      v-model="email"
      @input="updateEmail"
      v-validate="'required|email'"
      :has-error="errors.has('email')"
      :error="errors.first('email')"
      type="email"
      ariaLabel="general-email"
    ></input-text>
    <input-text
      name="phone_number"
      label="Phone Number"
      v-model="phone"
      @input="updatePhone"
      v-validate="{
        required: false,
        regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/
      }"
      :has-error="errors.has('phone_number')"
      :error="errors.first('phone_number')"
      ariaLabel="general-phone"
    ></input-text>
    <input-textarea
      name="description"
      label="Description"
      @input="updateDesc"
      ariaLabel="general-description"
    ></input-textarea>
    <button class="button" @click="submit">Send</button>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import InputText from './elements/InputText.vue';
import InputTextarea from './elements/InputTextarea.vue';
import InputRadio from './elements/InputRadio.vue';

export default {
  name: 'media-enquiry',
  components: { InputText, InputTextarea, InputRadio },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      description: '',
      enquiryType: '',
      radioFields: [
        {
          value: 'Enquire about media resources',
          label: 'Enquire about media resources',
        },
        {
          value: 'Speak to a communications officer',
          label: 'Speak to a communications officer',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
    };
  },
  methods: {
    /**
     * Update name field for submission
     */
    updateName(data) {
      this.name = data;
    },
    /**
     * Update email field for submission
     */
    updateEmail(data) {
      this.email = data;
    },
    /**
     * Update phone field for submission
     */
    updatePhone(data) {
      this.phone = data;
    },
    /**
     * Update description field for submission
     */
    updateDesc(data) {
      this.description = data;
    },
    /**
     * Update enquiry type
     */
    updateType(data) {
      this.enquiryType = data;
    },
    /**
     * Validate then submit form
     */
    submit() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          const csrf = Cookies.get('csrftoken');

          axios.post('', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            enquiry_type: this.enquiryType,
            description: this.description,
            request_type: 'media',
          }, { headers: { 'X-CSRFToken': csrf } })
            .then(() => {
              this.$emit('success');
            })
            .catch(() => {
              this.$emit('failure');
            });
        }
      });
    },
  },
};
</script>
