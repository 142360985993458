<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#E20000" fill-rule="evenodd" d="M3.98 1.073A7.854 7.854 0 0 1 8 0c1.451 0 2.79.358 4.016 1.073a7.964 7.964 0 0 1 2.911 2.911A7.826 7.826 0 0 1 16 8c0 1.451-.358 2.79-1.073 4.016a7.964 7.964 0 0 1-2.911 2.911A7.826 7.826 0 0 1 8 16a7.826 7.826 0 0 1-4.016-1.073 7.964 7.964 0 0 1-2.911-2.911A7.826 7.826 0 0 1 0 8c0-1.451.358-2.79 1.073-4.016a7.976 7.976 0 0 1 2.906-2.911zM9.332 12.99v-1.98a.342.342 0 0 0-.093-.244.304.304 0 0 0-.23-.1h-2a.33.33 0 0 0-.24.105.33.33 0 0 0-.103.24v1.979c0 .09.034.17.104.24.07.069.15.103.24.103h2c.09 0 .166-.033.229-.099a.342.342 0 0 0 .093-.244zm-.02-3.584L9.5 2.938a.205.205 0 0 0-.104-.188.388.388 0 0 0-.25-.083H6.854a.388.388 0 0 0-.25.083.205.205 0 0 0-.104.188l.177 6.468c0 .07.035.13.104.183.07.052.153.078.25.078h1.927a.383.383 0 0 0 .245-.078.263.263 0 0 0 .11-.183z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-alert',
};
</script>
