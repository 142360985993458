<template>
  <div class="general-enquiry">
    <input-text
      name="name"
      label="Name *"
      v-model="name"
      @input="updateName"
      v-validate="'required'"
      :has-error="errors.has('name')"
      :error="errors.first('name')"
      ariaLabel="general-name"
    ></input-text>
    <input-text
      name="organisation"
      label="Organisation (if applicable)"
      @input="updateOrganisation"
      ariaLabel="organisation-name"
    ></input-text>
    <input-text
      name="position"
      label="Position (if applicable)"
      @input="updatePosition"
      ariaLabel="position-type"
    ></input-text>
    <input-text
      name="email"
      label="Email *"
      v-model="email"
      @input="updateEmail"
      v-validate="'required|email'"
      :has-error="errors.has('email')"
      :error="errors.first('email')"
      type="email"
      ariaLabel="general-email"
    ></input-text>
    <input-text
      name="phone_number"
      label="Phone Number"
      @input="updatePhone"
      ariaLabel="general-phone"
    ></input-text>
    <input-select
      name="program-type"
      label="Programme area of interest:"
      labelId="program-type"
      :selectFields="programAreas"
      v-validate="'required'"
      v-model="program_area"
      @input="updateProgramme"
      :has-error="errors.has('program-type')"
      :error="errors.first('program-type')"
      aria-label="program-type"
    ></input-select>
    <input-select
      name="creative-area"
      label="Creative area of interest"
      labelId="creative-area"
      :selectFields="creativeArea"
      v-validate="'required'"
      v-model="creative_area"
      @input="updateCreativeArea"
      :has-error="errors.has('creative-area')"
      :error="errors.first('creative-area')"
      aria-label="creative-area"
    ></input-select>
    <input-textarea
      name="message"
      label="Message"
      @input="updateMessage"
      ariaLabel="general-message"
    ></input-textarea>
    <button class="button" @click="submit">Send</button>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import InputText from './elements/InputText.vue';
import InputSelect from './elements/InputSelect.vue';
import InputTextarea from './elements/InputTextarea.vue';

export default {
  name: 'general-enquiry',
  components: { InputText, InputTextarea, InputSelect },
  data() {
    return {
      name: '',
      organisation: '',
      position: '',
      email: '',
      phone: '',
      message: '',
      program_area: '',
      creative_area: '',
      programAreas: [
        {
          value: '',
          label: 'Select option',
        },
        {
          value: 'Skills Development',
          label: 'Skills Development',
        },
        {
          value: 'Networking Events',
          label: 'Networking Events',
        },
        {
          value: 'Residencies',
          label: 'Residencies',
        },
        {
          value: 'Workspace',
          label: 'Workspace',
        },
        {
          value: 'Rehearsal Spaces',
          label: 'Rehearsal Spaces',
        },
        {
          value: 'All of the above',
          label: 'All of the above',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
      creativeArea: [
        {
          value: '',
          label: 'Select option',
        },
        {
          value: 'Animation',
          label: 'Animation',
        },
        {
          value: 'Dance',
          label: 'Dance',
        },
        {
          value: 'Design',
          label: 'Design',
        },
        {
          value: 'Circus',
          label: 'Circus',
        },
        {
          value: 'Community Theatre',
          label: 'Community Theatre',
        },
        {
          value: 'Film/Video',
          label: 'Film/Video',
        },
        {
          value: 'Musical Theatre',
          label: 'Musical Theatre',
        },
        {
          value: 'New Technologies',
          label: 'New Technologies',
        },
        {
          value: 'Physical Theatre',
          label: 'Physical Theatre',
        },
        {
          value: 'Theatre',
          label: 'Theatre',
        },
        {
          value: 'Writing',
          label: 'Writing',
        },
        {
          value: 'Music',
          label: 'Music',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
    };
  },
  methods: {
    /**
     * Update name field for submission
     */
    updateName(data) {
      this.name = data;
    },
    /**
     * Update business name field for submission
     */
    updateOrganisation(data) {
      this.organisation = data;
    },
    /**
     * Update business type field for submission
     */
    updatePosition(data) {
      this.position = data;
    },
    /**
     * Update email field for submission
     */
    updateEmail(data) {
      this.email = data;
    },
    /**
     * Update phone field for submission
     */
    updatePhone(data) {
      this.phone = data;
    },
    /**
     * Update program area field for submission
     */
    updateProgramme(data) {
      this.program_area = data;
    },
    /**
     * Update creative area field for submission
     */
    updateCreativeArea(data) {
      this.creative_area = data;
    },
    /**
     * Update questions field for submission
     */
    updateMessage(data) {
      this.message = data;
    },
    /**
     * Validate then submit form
     */
    submit() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          const csrf = Cookies.get('csrftoken');

          axios.post('', {
            name: this.name,
            organisation: this.organisation,
            position: this.position,
            email: this.email,
            phone: this.phone,
            message: this.message,
            program_area: this.program_area,
            creative_area: this.creative_area,
            request_type: 'program',
          }, { headers: { 'X-CSRFToken': csrf } })
            .then(() => {
              this.$emit('success');
            })
            .catch(() => {
              this.$emit('failure');
            });
        }
      });
    },
  },
};
</script>
