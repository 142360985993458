<template>
  <div class="event-filter__grid container">

    <div class="columns is-multiline event-filter__grid__columns">
      <div v-for="event in events" :key="event.id" class="column is-half-tablet is-4-desktop">
        <event-card
          :title="event.title"
          :event-id="event.id"
          :page-url="event.url"
          :genre="event.genre"
          :image="event.thumbnail"
          :dates="event.date_range"
          :label="event.label"
          :custom-label="event.custom_label"
          :in-past="event.in_past"
          :on-sale="event.on_sale"
          :ticket-button-text="event.ticket_button_text"
        ></event-card>
      </div>
    </div>

    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
    >
      <div slot="spinner">
        <load-more-animation />
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
/* eslint-disable-next-line import/no-unresolved */
import InfiniteLoading from 'vue-infinite-loading';

import EventCard from '@/components/events/EventCard.vue';
import LoadMoreAnimation from '@/components/standard/LoadMoreAnimation.vue';

export default {
  components: {
    EventCard,
    InfiniteLoading,
    LoadMoreAnimation,
  },
  methods: {
    ...mapActions([
      'eventFilter/getEvents',
    ]),
    /**
    * Handler for infinite loading component.
    * If vuex store is true it will get next performances and set state to loaded
    * otherwise if no more posts, sets state to complete
    */
    infiniteHandler($state) {
      if (this.morePages) {
        setTimeout(() => this.$store.dispatch('eventFilter/getEvents').then(() => {
          $state.loaded();
        }), 500);
      } else {
        $state.complete();
      }
    },
  },
  computed: {
    ...mapState({
      showPastEvents: state => state.eventFilter.showPastEvents,
      events: state => state.eventFilter.events,
      morePages: state => state.eventFilter.morePages,
      infiniteId: state => state.eventFilter.infiniteId,
    }),
  },
};
</script>
