<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#8785FF" fill-rule="evenodd" d="M20 0c-2.764 0-5.36.522-7.785 1.567C9.79 2.61 7.676 4.035 5.874 5.839c-1.802 1.804-3.232 3.92-4.289 6.348C.528 14.615 0 17.213 0 19.98c0 2.767.528 5.364 1.585 7.792s2.487 4.544 4.289 6.348c1.802 1.804 3.916 3.235 6.341 4.293C14.641 39.471 17.235 40 20 40c2.764 0 5.36-.529 7.785-1.587 2.425-1.058 4.539-2.489 6.341-4.293 1.802-1.804 3.232-3.92 4.289-6.348C39.472 25.344 40 22.747 40 19.98c0-2.767-.528-5.365-1.585-7.793s-2.487-4.544-4.289-6.348c-1.802-1.804-3.916-3.228-6.341-4.272C25.359.522 22.765 0 20 0zm4.715 13.795h-2.967c-.19 0-.366.108-.528.325a1.253 1.253 0 0 0-.244.773v2.198h3.74l-.529 3.092h-3.211v9.278h-3.537v-9.278h-3.211v-3.092h3.211v-1.832c0-1.302.407-2.42 1.22-3.357.813-.936 1.842-1.404 3.089-1.404h2.967v3.297z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-social-facebook',
};
</script>
