<template>
  <p class="event-label" :class="labelClass">{{ labelText }}</p>
</template>

<script>
export default {
  name: 'event-label',
  props: [
    'labelType',
    'labelText',
  ],
  computed: {
    labelClass() {
      if (this.labelType === 'event-tag') {
        return 'event-label--tag has-background-blue-tint-lighter has-text-blue';
      }
      if (this.labelType === 'genre') return `event-label--type ${this.labelColour}`;
      if (this.labelType === 'large') return `event-label--large ${this.labelColour}`;
      return this.labelColour;
    },
    labelColour() {
      if (this.labelText === 'Selling Fast!') return 'has-background-notify-blue';
      if (this.labelText === 'Free Event') return 'has-background-notify-green';
      if (this.labelText === 'Sold Out') return 'has-background-notify-red';
      if (this.labelText === 'Past Event') return 'has-background-notify-red';
      return 'has-background-pink has-text-blue';
    },
  },
};
</script>
