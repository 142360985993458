<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 258">
    <defs>
      <mask id="header-shape-mask" width="1000" height="258" >
        <rect width="1000" height="258" fill="#fff"/>
        <!-- eslint-disable-next-line max-len -->
        <path d="M217.802 0H3.198A109.885 109.885 0 0 0 0 26.298C0 87.089 49.473 136.37 110.499 136.37c20.303 0 39.308-5.478 55.657-14.996l-107.12 84.022L100.64 258l111.865-87.74-41.188-52.074C201.244 98.491 221 64.701 221 26.298 221 17.23 219.877 8.43 217.802 0z"/>
      </mask>
    </defs>
    <rect
      id="header-shape-fill"
      width="1000"
      height="258"
      mask="url(#header-shape-mask)"
      :fill="background"
    />
  </svg>
</template>

<script>
export default {
  name: 'svg-logo-mask',
  props: ['background'],
};
</script>
