<template>
  <svg viewBox="0 0 325 379" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable-next-line max-len -->
    <path d="M324.432 38.631c0-13.319-1.649-26.248-4.695-38.631H4.695A161.522 161.522 0 0 0 0 38.631c0 89.302 72.628 161.696 162.214 161.696 29.805 0 57.706-8.048 81.706-22.029L86.665 301.726 147.742 379l164.22-128.888-60.464-76.497c43.932-28.932 72.934-78.57 72.934-134.984" fill="#C6C4FF" fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'loading-svg',
};
</script>
