<template>
  <div
    class="nav-menu__wrap"
    :class="{ 'is-active' : active }"
    :id="`${elementId}-menu`"
    :aria-expanded="active.toString()"
  >
    <ul class="nav-menu__list">
      <li class="nav-menu__item nav-menu__item--back">
        <button
          class="nav-menu__back"
          title="go back to parent menu"
          :aria-controls="`${elementId}-menu`"
          :tabindex="tabIndex"
          @click="activateParent()"
        >
          <span class="icon icon--arrow is-white" aria-hidden="true">
            <svg-arrow></svg-arrow>
          </span>
          <span>Back</span>
        </button>
      </li>
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SvgArrow from '@/svgs/Arrow.vue';

export default {
  name: 'child-nav',
  props: {
    parentId: Number,
    page: Number,
    elementId: String,
  },
  components: { SvgArrow },
  computed: {
    ...mapState({
      activeChild: state => state.menu.activeChild,
    }),
    active() {
      return this.activeChild[this.activeChild.length - 1] === this.parentId;
    },
    tabIndex() {
      return this.active ? '0' : '-1';
    },
  },
  methods: {
    activateParent() {
      this.$store.commit('menu/activateParent');
    },
  },
};
</script>
