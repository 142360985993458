/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const state = {
  activeSection: null,
};

const mutations = {
  /**
   * Set the activeSection
   */
  setActiveSection(state, payload) {
    state.activeSection = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
