<template>
<div>
  <div class="shortlist-sidebar__list__links">
    <div class="container">
      <p
        class="is-meta is-family-monospace has-text-weight-bold has-text-blue is-uppercase">
        Share your shortlist
      </p>
      <a
        :href="shortlistUrl"
        class="button has-background-blue-tint-lighter is-cta"
      >
        <span class="icon icon--external">
          <External/>
        </span>
        <span class="button-cta-text">Link to shortlist </span>
      </a>
      <br>
      <button
        class="button has-background-blue-tint-lighter is-cta is-paddingless"
        v-clipboard:copy="shortlistUrl"
        v-clipboard:success="onCopy"
      >
        <span class="icon icon--link"><Link/></span>
        <span class="button-cta-text">Copy link</span>
        <transition name="fade">
          <span
            class="link-copied-message has-text-weight-normal"
            v-if="showCopiedLinkMessage">
            Copied! &nbsp;
            <span class="icon icon--check">
              <Check/>
            </span>
          </span>
        </transition>
      </button>
    </div>
  </div>
  <div class="container shortlist-page__events">
    <div class="columns is-multiline">
      <div
        v-for="event in events"
        :key="event"
        class="column is-half-tablet is-4-desktop">
          <EventCard
            :eventId="event"
          />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import * as queryString from 'query-string';

import EventCard from '@/components/events/EventCard.vue';
import Link from '@/svgs/Link.vue';
import External from '@/svgs/External.vue';
import Check from '@/svgs/Check.vue';

export default {
  components: {
    EventCard,
    Link,
    External,
    Check,
  },
  data() {
    return {
      events: [],
      showCopiedLinkMessage: false,
    };
  },
  mounted() {
    /**
    * parses browser's search string.
    * it sets the events data to that. If there is one ID, it adds it to an array
    */
    // https://github.com/sindresorhus/query-string and passes to get filters

    const parsedQueryString = queryString.parse(window.location.search);
    if (parsedQueryString.events) {
      const eventIds = parsedQueryString.events.split(',').map(id => parseInt(id, 10));
      this.events = eventIds;
    }
  },
  methods: {
    onCopy() {
      /**
      * shows copy message for 4 seconds
      */
      clearTimeout(this.hideCopiedLinkMessageTimeout);
      this.showCopiedLinkMessage = true;
      this.hideCopiedLinkMessageTimeout = setTimeout(() => {
        this.showCopiedLinkMessage = false;
      }, 4000);
    },
  },
  computed: {
    ...mapState({
      shortlistEvents: state => state.shortlist.shortlistEvents,
    }),
    shortlistUrl() {
      /**
      * creates url for sharing event shortlists
      * @returns a string of concatinated ids from the events in the shortlist array
      * from vuex state.
      */
      const arrayOfIds = this.shortlistEvents.join();
      return `${process.env.VUE_APP_BASE_URL}shortlist/?events=${arrayOfIds}`;
    },
  },
};
</script>
