<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" fill-rule="evenodd" d="M6.874 13.523c.29.288.434.629.434 1.022 0 .394-.145.735-.434 1.023-.29.288-.632.432-1.028.432s-.738-.144-1.027-.432a1.395 1.395 0 0 1-.434-1.023c0-.393.144-.734.434-1.022a1.405 1.405 0 0 1 1.027-.432c.396 0 .739.144 1.028.432zm10.23 0c.29.288.434.629.434 1.022 0 .394-.144.735-.433 1.023-.29.288-.632.432-1.028.432s-.738-.144-1.028-.432a1.395 1.395 0 0 1-.434-1.023c0-.393.145-.734.434-1.022.29-.288.632-.432 1.028-.432s.738.144 1.028.432zM19 2.182V8a.703.703 0 0 1-.188.483.723.723 0 0 1-.463.244L6.43 10.114c.098.454.148.72.148.795 0 .121-.091.364-.274.727h10.505c.198 0 .369.072.514.216a.697.697 0 0 1 .216.512.697.697 0 0 1-.216.511.704.704 0 0 1-.514.216H5.115a.704.704 0 0 1-.513-.216.697.697 0 0 1-.217-.511c0-.084.03-.203.091-.358a4.32 4.32 0 0 1 .183-.41c.06-.117.142-.268.245-.454.103-.186.162-.297.177-.335l-2.02-9.352H.73a.704.704 0 0 1-.514-.216A.697.697 0 0 1 0 .727C0 .53.072.36.217.216A.704.704 0 0 1 .73 0h2.923a.698.698 0 0 1 .548.25c.053.068.103.161.148.278.046.118.076.216.092.296a59.832 59.832 0 0 1 .114.63h13.713c.198 0 .37.073.514.216a.697.697 0 0 1 .217.512z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-cart',
};
</script>
