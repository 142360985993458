<template>
  <flickity-carousel type="event" size="half" :is-full="isFull">
    <div class="carousel-cell" v-for="eventId in events" :key="eventId">
      <event-card :eventId="eventId"></event-card>
    </div>
  </flickity-carousel>
</template>

<script>
import axios from 'axios';
import EventCard from '@/components/events/EventCard.vue';
import FlickityCarousel from '@/components/blocks/FlickityCarousel.vue';

export default {
  name: 'related-events',
  props: {
    eventIds: Array,
    genre: Number,
    upcoming: {
      type: Boolean,
      default: false,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  components: { EventCard, FlickityCarousel },
  mounted() {
    this.events = this.eventIds;
    if (this.eventIds.length >= 3 || this.upcoming === false) {
      return;
    }
    const pagesMissing = 3 - this.eventIds.length;
    const exclude = this.events.length > 0 ? `&exclude=${this.events.join(',')}` : '';
    let url = `${process.env.VUE_APP_API_URL}event_pages/?limit=${pagesMissing}${exclude}`;
    if (this.genre) {
      url = `${url}&genre=${this.genre}`;
    }
    axios.get(url)
      .then((response) => {
        response.data.items.forEach((item) => {
          this.events.push(item.id);
        });
        this.$children[0].resize();
      });
  },
};
</script>
