<template>
  <div class="news-index__pages">
    <div class="columns is-multiline">
      <div class="column is-6-tablet is-4-desktop" :key="card" v-for="card in cards">
        <news-card :pageId="card"></news-card>
      </div>
    </div>
    <div v-if="has_next" @click="loadMore" class="news-index__more">
      <!-- todo: add loading animation. -->
      <span class="icon icon--loadmore"><loading-svg></loading-svg></span>
      <span class="news-index__load-text">Load more...</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NewsCard from './NewsCard.vue';
import LoadingSvg from '@/svgs/LoadingSvg.vue';

export default {
  name: 'news-card-list',
  components: { LoadingSvg, NewsCard },
  props: ['pageUrl'],
  data() {
    return {
      page: 0,
      cards: [],
      loading: false,
      has_next: false,
    };
  },
  mounted() {
    this.loadMore();
  },
  methods: {
    /**
     * load more news items with axios.
     */
    loadMore() {
      this.loading = true;
      this.page += 1;
      // note this is not the API url.
      axios.get(`${this.pageUrl}?page=${this.page}`, {
        headers: {
          // add header for Django is_ajax method.
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
        .then((response) => {
          if (response.data.number !== 1) {
            // response will return last page if page number it out of range.
            // therefore if `number` is 1 it is almost certainly wrong.
            this.cards = this.cards.concat(response.data.items);
          }
          this.loading = false;
          this.has_next = response.data.has_next;
        });
    },
  },
};
</script>
