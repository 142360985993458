<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#3D854C" fill-rule="evenodd" d="M2.321 2.357C3.893.786 5.786 0 8 0c2.214 0 4.101.78 5.66 2.34C15.22 3.898 16 5.785 16 8s-.78 4.101-2.34 5.66C12.102 15.22 10.215 16 8 16s-4.101-.78-5.66-2.34C.78 12.102 0 10.215 0 8s.774-4.095 2.321-5.643zm9.645 2.84c.023-.026.034-.063.034-.113 0-.075-.011-.124-.034-.15l-1.07-.897C10.875 4.012 10.829 4 10.76 4c-.046 0-.08.012-.104.037L6.828 9.383a834.655 834.655 0 0 1-1.552-1.607c-.07-.075-.138-.112-.207-.112-.023 0-.069.037-.138.112l-.862.934-.035.038c-.023.025-.034.074-.034.15 0 .049.011.086.034.111l.07.038 2.724 2.84c.069.076.126.113.172.113.046 0 .103-.037.172-.112l4.794-6.692z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-check',
};
</script>
