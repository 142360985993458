<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" d="M9.807 16.458a6.65 6.65 0 1 1 0-13.3 6.65 6.65 0 0 1 0 13.3m13.891 4.926l-5.897-5.898a9.758 9.758 0 0 0 1.814-5.679C19.615 4.391 15.224 0 9.807 0 4.391 0 0 4.39 0 9.807c0 5.417 4.39 9.808 9.807 9.808a9.757 9.757 0 0 0 5.657-1.8l5.9 5.902a.47.47 0 0 0 .664 0l1.67-1.67a.47.47 0 0 0 0-.663"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-search',
};
</script>
