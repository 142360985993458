<template>
  <div class="column is-3-desktop is-offset-1-desktop">
    <div class="sidebar-container" :class="{ 'nav-active': navActive }">
      <button
        class="sidebar-toggle is-hidden-desktop"
        :class="{ 'is-active': sidebarExpanded }"
        title="Expand child pages navigation"
        aria-controls="child-pages-navigation"
        :aria-expanded="ariaExpanded"
        @click="toggleSidebar"
      >
        <div class="sidebar-toggle__wrap">
          <span class="is-regular has-text-weight-bold">{{ headerTitle }}</span>
          <span aria-hidden="true" class="icon icon--arrow-wide">
            <svg-arrow-wide></svg-arrow-wide>
          </span>
        </div>
      </button>
      <div :class="[headerClass, { 'is-expanded': sidebarExpanded }]">
        <div class="sidebar-menu__wrap">
          <slot name="header"></slot>
        </div>
      </div>
      <ul
        class="sidebar-menu"
        :class="{ 'is-expanded': sidebarExpanded }"
        id="child-pages-navigation"
      >
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SvgArrowWide from '@/svgs/ArrowWide.vue';

export default {
  name: 'sidebar',
  props: {
    headerTitle: String,
    crumb: Boolean,
  },
  components: { SvgArrowWide },
  data() {
    return {
      sidebarExpanded: false,
    };
  },
  computed: {
    ...mapState({
      navActive: state => state.menu.navActive,
    }),
    ariaExpanded() {
      return this.sidebarExpanded ? 'true' : 'false';
    },
    headerClass() {
      return this.crumb ? 'sidebar-menu__header--crumb' : 'sidebar-menu__header';
    },
  },
  methods: {
    /**
     * Expand the sidebar on mobile views
     */
    toggleSidebar() {
      this.sidebarExpanded = !this.sidebarExpanded;
    },
  },
};
</script>
