<template>
  <div
    v-show="isActive"
    :aria-labelledby="label"
    :id="`${label}-tab`"
    :hidden="!isActive"
    tabindex="0"
    class="tab-content"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    name: {
      required: true,
    },
    label: {
      required: true,
    },
    selected: {
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    /**
     * Set to active if selected property.
     */
    this.isActive = this.selected;
  },
};
</script>
