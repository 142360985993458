<template>
  <div>
    <p class="is-large has-text-weight-bold has-text-blue">{{ selectTitle }}</p>
    <ul class="event-performance__list">
      <slot></slot>
    </ul>
    <div v-if="eventLink" class="performance-list__link-container">
      <a class="performance-list__link" :href="eventLink">
        <span>About the event</span>
        <span class="icon icon--arrow"><arrow-svg></arrow-svg></span>
      </a>
    </div>
  </div>
</template>

<script>
import ArrowSvg from '@/svgs/Arrow.vue';

export default {
  name: 'performance-list',
  props: {
    title: String,
    eventLink: String,
  },
  components: { ArrowSvg },
  computed: {
    selectTitle() {
      if (this.title) {
        return `Select "${this.title}" performance`;
      }
      return 'Select performance';
    },
  },
};
</script>
