<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" fill-rule="evenodd" d="M16 12.636c0 .205-.038.472-.114.802a4.482 4.482 0 0 1-.238.778c-.16.379-.621.78-1.387 1.204-.712.387-1.416.58-2.113.58-.205 0-.404-.013-.597-.04a4.912 4.912 0 0 1-.653-.142 9.264 9.264 0 0 1-.54-.165 44.75 44.75 0 0 1-.63-.233 24.219 24.219 0 0 0-.558-.204 10.292 10.292 0 0 1-1.988-.943c-.97-.599-1.972-1.415-3.006-2.45-1.034-1.033-1.85-2.035-2.449-3.005A10.292 10.292 0 0 1 .784 6.83c-.023-.07-.09-.254-.204-.557a44.75 44.75 0 0 1-.233-.631 9.264 9.264 0 0 1-.165-.54A4.912 4.912 0 0 1 .04 4.45 4.384 4.384 0 0 1 0 3.852c0-.697.193-1.401.58-2.113C1.004.973 1.405.51 1.784.352a4.51 4.51 0 0 1 .778-.238A3.75 3.75 0 0 1 3.364 0c.106 0 .185.011.238.034.137.046.337.333.603.864.083.144.197.348.34.613.144.266.277.506.398.722.121.216.239.419.352.608.023.03.09.125.2.284.11.16.19.294.244.403.053.11.08.218.08.324 0 .152-.109.341-.325.568a5.54 5.54 0 0 1-.704.625c-.254.19-.489.39-.705.603-.216.212-.324.386-.324.522 0 .069.02.154.057.256.038.102.07.18.097.233.026.053.08.144.159.273.08.129.123.2.13.216.576 1.038 1.235 1.928 1.978 2.67.742.743 1.632 1.402 2.67 1.977l.216.131c.129.08.22.133.273.16.053.026.13.058.233.096a.768.768 0 0 0 .256.057c.136 0 .31-.108.522-.324.212-.216.413-.451.603-.705a5.54 5.54 0 0 1 .625-.704c.227-.216.416-.324.568-.324a.73.73 0 0 1 .324.08c.11.052.244.134.403.244.16.11.254.176.284.199.19.113.392.23.608.352.216.121.456.254.722.398.265.143.47.257.613.34.53.266.818.466.864.603a.636.636 0 0 1 .034.238z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-phone',
};
</script>
