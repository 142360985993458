<template>
  <div class="event-page-bottom-menu" ref="eventPageBottomMenu">
    <headroom footroom :offset="menuOffsetFromTop">
      <slot></slot>
    </headroom>
  </div>
</template>

<script>
import { headroom } from 'vue-headroom';

export default {
  components: {
    headroom,
  },
  data() {
    return {
      menuOffsetFromTop: 0,
    };
  },
  mounted() {
    this.menuOffsetFromTop = this.$refs.eventPageBottomMenu.getBoundingClientRect().top;
  },
};
</script>
