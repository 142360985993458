<template>
  <a :href="url" class="card-program" :style="`background-color: #${backgroundColour}`">
    <h6 class="card-program__title" :class="`has-text-${textColour}`">{{ title }}</h6>
    <img class="card-program__img" :src="thumbnail" :alt="alt" />
  </a>
</template>

<script>
export default {
  name: 'program-card',
  props: ['url', 'title', 'backgroundColour', 'textColour', 'thumbnail', 'alt'],
};
</script>
