var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flickity-carousel"},[_c('flickity',{ref:"flickity",staticClass:"carousel",class:_vm.classes,attrs:{"options":{
      wrapAround: _vm.wrapAround,
      groupCells: '108%',
      cellAlign: _vm.cellAlign,
      percentPosition: false,
      arrowShape: 'M72 77.2 64.833 84 29 50 64.833 16 72 22.8 43.557 50z',
      autoPlay: _vm.autoPlay,
      setGallerySize: _vm.setGallerySize,
      adaptiveHeight: false,
      prevNextButtons: _vm.prevNextButtons,
      pageDots: _vm.showDots,
    }}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }