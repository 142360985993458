import Vue from 'vue';
import VCalendar from 'v-calendar-ie11';
import VeeValidate from 'vee-validate';
import VueClipboard from 'vue-clipboard2';
import vueSmoothScroll from 'vue2-smooth-scroll';
import objectFitImages from 'object-fit-images';
import App from './App.vue';
import store from './store';

import BackgroundImageSlider from '@/components/standard/BackgroundImageSlider.vue';
import BlockQuote from '@/components/blocks/BlockQuote.vue';
import BuyTickets from '@/components/events/BuyTickets.vue';
import FlickityCarousel from '@/components/blocks/FlickityCarousel.vue';
import ContactForms from '@/components/forms/ContactForms.vue';
import EventCalendar from '@/components/events/EventCalendar.vue';
import EventCard from '@/components/events/EventCard.vue';
import EventFilterFilters from '@/components/events/EventFilter/EventFilterFilters.vue';
import EventFilterGrid from '@/components/events/EventFilter/EventFilterGrid.vue';
import EventFilterSearch from '@/components/events/EventFilter/EventFilterSearch.vue';
import EventLabel from '@/components/events/EventLabel.vue';
import EventPerformance from '@/components/events/EventPerformance.vue';
import EventPageBottomMenu from '@/components/events/EventPageBottomMenu.vue';
import ExpandableSection from '@/components/standard/ExpandableSection.vue';
import ExpandableSectionSidebar from '@/components/standard/ExpandableSectionSidebar.vue';
import Faq from '@/components/faqs/Faq.vue';
import FaqIndex from '@/components/faqs/FaqIndex.vue';
import NewsCard from '@/components/news/NewsCard.vue';
import NewsCardList from '@/components/news/NewsCardList.vue';
import PerformanceList from '@/components/events/PerformanceList.vue';
import PerformanceModal from '@/components/events/PerformanceModal.vue';
import PopupMessageModal from '@/components/PopupMessageModal.vue';
import ProgramCard from '@/components/programs/ProgramCard.vue';
import ProgramEvents from '@/components/events/ProgramEvents.vue';
import ShortlistHeart from '@/components/events/ShortlistHeart.vue';
import ShortlistSidebar from '@/components/events/ShortlistSidebar.vue';
import ShortlistPage from '@/components/events/ShortlistPage.vue';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import SidebarBlock from '@/components/sidebar/SidebarBlock.vue';
import RelatedEvents from '@/components/blocks/RelatedEvents.vue';
import Tab from '@/components/tabs/Tab.vue';
import Tabs from '@/components/tabs/Tabs.vue';


// Nav
import NavContainer from '@/components/nav/NavContainer.vue';
import NavItem from '@/components/nav/NavItem.vue';
import ChildNav from '@/components/nav/ChildNav.vue';
import SignUpForm from '@/components/forms/SignUpForm.vue';

// Svgs
import SvgAlert from '@/svgs/Alert.vue';
import SvgAllEvents from '@/svgs/AllEvents.vue';
import SvgArrow from '@/svgs/Arrow.vue';
import SvgArrowWide from '@/svgs/ArrowWide.vue';
import SvgCalendar from '@/svgs/Calendar.vue';
import SvgCheck from '@/svgs/Check.vue';
import SvgDownload from '@/svgs/Download.vue';
import SvgExternal from '@/svgs/External.vue';
import SvgHeart from '@/svgs/Heart.vue';
import SvgHeartLarge from '@/svgs/HeartLarge.vue';
import SvgHeartSimple from '@/svgs/HeartSimple.vue';
import SvgLink from '@/svgs/Link.vue';
import SvgLogoLockup from '@/svgs/LogoLockup.vue';
import SvgLogoMask from '@/svgs/LogoMask.vue';
import SvgMinus from '@/svgs/Minus.vue';
import SvgPlus from '@/svgs/Plus.vue';
import SvgRemove from '@/svgs/Remove.vue';
import SvgSearch from '@/svgs/Search.vue';
import SvgSocialFacebook from '@/svgs/SocialFacebook.vue';
import SvgSocialInstagram from '@/svgs/SocialInstagram.vue';
import SvgSocialTwitter from '@/svgs/SocialTwitter.vue';
import SvgStarFull from '@/svgs/StarFull.vue';
import SvgStarHalf from '@/svgs/StarHalf.vue';
import SvgTicket from '@/svgs/Ticket.vue';
import SvgUpload from '@/svgs/Upload.vue';

// Styleguide
import './styleguide';

objectFitImages(); // Remove this line after all styleguide JS moved to Vue

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(VCalendar);
Vue.use(VueClipboard);
Vue.use(vueSmoothScroll);

new Vue({ // eslint-disable-line no-new
  el: '#nav',
  components: {
    NavContainer,
    NavItem,
    ChildNav,
  },
  store,
});

new Vue({ // eslint-disable-line no-new
  el: '#footer',
  components: {
    SvgLogoLockup,
    SvgSocialFacebook,
    SvgSocialInstagram,
    SvgSocialTwitter,
  },
});

new Vue({ // eslint-disable-line no-new
  el: '#content',
  components: {
    App,
    BackgroundImageSlider,
    BlockQuote,
    BuyTickets,
    FlickityCarousel,
    ContactForms,
    EventCalendar,
    EventCard,
    EventFilterFilters,
    EventFilterGrid,
    EventFilterSearch,
    EventLabel,
    EventPerformance,
    EventPageBottomMenu,
    ExpandableSection,
    ExpandableSectionSidebar,
    Faq,
    FaqIndex,
    NewsCard,
    NewsCardList,
    PerformanceList,
    PerformanceModal,
    PopupMessageModal,
    ProgramCard,
    ProgramEvents,
    ShortlistHeart,
    ShortlistSidebar,
    ShortlistPage,
    RelatedEvents,
    Sidebar,
    SidebarBlock,
    SignUpForm,
    Tab,
    Tabs,

    // Svgs
    SvgAllEvents,
    SvgCalendar,
    SvgAlert,
    SvgArrow,
    SvgArrowWide,
    SvgCheck,
    SvgDownload,
    SvgExternal,
    SvgHeart,
    SvgHeartLarge,
    SvgHeartSimple,
    SvgLink,
    SvgLogoLockup,
    SvgLogoMask,
    SvgMinus,
    SvgPlus,
    SvgRemove,
    SvgSearch,
    SvgStarFull,
    SvgStarHalf,
    SvgTicket,
    SvgUpload,
  },
  store,
});

function setUpFormCollapse() {
  const forms = document.getElementsByClassName('temp-form-collapse');
  if (forms.length < 1) {
    return;
  }
  const form = forms[0];
  form.addEventListener('click', () => {
    form.classList.remove('temp-form-collapse');
  });
}

setUpFormCollapse();
