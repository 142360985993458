<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 11">
    <path fill="#06038D" fill-rule="evenodd" d="M0 1.1L1.167 0 7 5.5 1.167 11 0 9.9l4.63-4.4z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-arrow',
};
</script>
