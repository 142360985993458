<template>
  <article class="blockquote">
    <div
      v-if="stars"
      class="blockquote-stars"
      role="presentation"
      :aria-label="`star rating: ${stars}`"
    >
      <span class="icon icon--star" aria-hidden="true" v-for="i in roundedStars" :key="i">
        <svg-star-full v-if="i < stars || i === stars"></svg-star-full>
        <svg-star-half v-if="(i > stars) && (i - 1 < stars)"></svg-star-half>
      </span>
    </div>
    <blockquote>
      <p>“{{ text }}”</p>
      <cite v-if="cite" class="blockquote-cite">{{ cite }}</cite>
    </blockquote>
  </article>
</template>

<script>
import SvgStarFull from '@/svgs/StarFull.vue';
import SvgStarHalf from '@/svgs/StarHalf.vue';

export default {
  name: 'block-quote',
  components: { SvgStarFull, SvgStarHalf },
  props: {
    stars: Number,
    text: String,
    cite: String,
  },
  computed: {
    roundedStars() {
      return Math.ceil(this.stars);
    },
  },
};
</script>
