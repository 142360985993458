<template>
  <button
    :class="{ 'is-filled' : eventSelected, 'button-heart' : type === 'heart' }"
    @click="toggleEvent"
  >
    <span class="icon" :class="iconClass" aria-hidden="true">
      <svg-heart-simple v-if="type === 'button'" class="fill-area"></svg-heart-simple>
      <svg-heart v-else-if="type === 'heart' && size === 'small'"></svg-heart>
      <svg-heart-large v-else></svg-heart-large>
    </span>
    <span v-if="type === 'button'">{{ buttonText }}</span>
  </button>
</template>

<script>
import { mapState } from 'vuex';

import SvgHeartSimple from '@/svgs/HeartSimple.vue';
import SvgHeart from '@/svgs/Heart.vue';
import SvgHeartLarge from '@/svgs/HeartLarge.vue';

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    eventTitle: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    type: {
      type: String,
      default: 'heart',
    },
  },
  components: {
    SvgHeart,
    SvgHeartLarge,
    SvgHeartSimple,
  },
  methods: {
    /**
    * send both event id and title to vuex mutation
    * both are required props
    */
    toggleEvent() {
      this.$store.commit('shortlist/toggleEvent', {
        id: this.eventId,
        title: this.eventTitle,
      });
    },
  },
  computed: {
    buttonText() {
      if (this.eventSelected) return 'Remove from shortlist';
      return 'Add to shortlist';
    },
    iconClass() {
      if (this.size === 'large') return 'icon--heart-large';
      if (this.size === 'small') return 'icon--heart';
      return '';
    },
    ...mapState({
      shortlistEvents: state => state.shortlist.shortlistEvents,
    }),
    /**
    * checks if current event is within the shortlist array of ids
    * @returns true if the current event id is within the shortlist array of ids
    */
    eventSelected() {
      return this.shortlistEvents.indexOf(this.eventId) !== -1;
    },
  },
};
</script>
