<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" d="M16 1.244L14.756 0 8 6.756 1.244 0 0 1.244 6.756 8 8 9.244 9.244 8z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-arrow-wide',
};
</script>
