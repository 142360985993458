<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#3A37CC" fill-rule="evenodd" d="M5.868 12.706l-.802.821a1.767 1.767 0 0 1-1.271.509 1.767 1.767 0 0 1-1.8-1.78c0-.235.046-.46.137-.675a1.81 1.81 0 0 1 .391-.577l2.973-2.953c.313-.3.724-.593 1.232-.88.509-.287.978-.215 1.409.215.195.182.43.274.704.274a.898.898 0 0 0 .684-.294.96.96 0 0 0 .294-.704.96.96 0 0 0-.294-.704c-.717-.704-1.568-.998-2.552-.88-.985.117-1.94.639-2.866 1.565L1.115 9.596a4.077 4.077 0 0 0-.822 1.232A3.588 3.588 0 0 0 0 12.256c0 .509.098.991.293 1.447.196.457.47.868.822 1.233a3.6 3.6 0 0 0 1.252.821 3.834 3.834 0 0 0 2.846 0 3.623 3.623 0 0 0 1.242-.821l.821-.822a.898.898 0 0 0 .293-.684.96.96 0 0 0-.293-.704.96.96 0 0 0-.704-.294.996.996 0 0 0-.704.274zm9.056-11.5C14.14.435 13.268.034 12.303.001 11.338-.03 10.497.305 9.78 1.01L8.763 2.027a.898.898 0 0 0-.294.684.96.96 0 0 0 .294.704.96.96 0 0 0 .704.294.996.996 0 0 0 .704-.274l.997-1.017c.379-.365.786-.502 1.223-.411a2.19 2.19 0 0 1 1.125.606 1.76 1.76 0 0 1 .528 1.271c0 .235-.046.46-.137.675a1.81 1.81 0 0 1-.391.577l-3.17 3.15c-.73.717-1.284 1.013-1.662.89-.378-.124-.639-.265-.782-.421a.996.996 0 0 0-.704-.274.898.898 0 0 0-.685.294.96.96 0 0 0-.293.704c0 .273.098.502.293.684.326.34.678.59 1.056.753.379.163.77.245 1.174.245.482 0 .978-.12 1.487-.362.508-.241 1.01-.61 1.506-1.105l3.188-3.15c.352-.364.626-.775.821-1.231.196-.457.294-.933.294-1.428 0-.509-.098-.991-.294-1.448a4.077 4.077 0 0 0-.821-1.232z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-link',
};
</script>
