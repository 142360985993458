/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const state = {
  shortlistEvents: [],
  lastEventTitleAdded: '',
  shortlistSidebarActive: false,
};

const mutations = {
  /**
   * toggle the state of the sidebar
   * @param {object} state
   */
  toggleSidebar(state) {
    state.shortlistSidebarActive = !state.shortlistSidebarActive;
  },
  /**
   * close the state of the sidebar
   * @param {object} state
   */
  closeSidebar(state) {
    state.shortlistSidebarActive = false;
  },
  /**
   * Toggle event in the shortlist
   * @param {object} state
   */
  toggleEvent(state, event) {
    if (event.id) {
      if (state.shortlistEvents.indexOf(event.id) === -1) {
        state.shortlistEvents = [event.id, ...state.shortlistEvents];
        state.lastEventTitleAdded = event.title;
      } else {
        state.shortlistEvents = state.shortlistEvents.filter(eventold => eventold !== event.id);
        state.lastEventTitleAdded = '';
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
