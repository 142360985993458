<template>
  <section class="form-container--small has-background-blue-tint-lighter">
    <div class="contact-forms" v-if="!success">
      <h4 class="title is-4 is-family-extended has-text-weight-medium has-text-blue">
        Make an enquiry
      </h4>
      <input-select
        name="enquiry-type"
        label="Enquiry type*"
        labelId="enquiry-type"
        :selectFields="formTypes"
        :selected="selected"
        @input="selectForm"
      ></input-select>
      <general-enquiry
        v-if="selected === 'general'"
        @success="isSuccess"
        @failure="hasFailed"
      ></general-enquiry>
      <media-enquiry
        v-if="selected === 'media'"
        @success="isSuccess"
        @failure="hasFailed"
      ></media-enquiry>
      <ticket-enquiry
        v-if="selected === 'ticketing'"
        @success="isSuccess"
        @failure="hasFailed"
      ></ticket-enquiry>
      <partnership-enquiry
        v-if="selected === 'partnership'"
        @success="isSuccess"
        @failure="hasFailed"
      ></partnership-enquiry>
      <program-enquiry
        v-if="selected === 'program'"
        @success="isSuccess"
        @failure="hasFailed"
      ></program-enquiry>
      <div class="error-message has-text-danger" v-if="failure">
        <br>There was an error submitting the form.
      </div>
    </div>
    <div v-if="success">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import InputSelect from './elements/InputSelect.vue';
import GeneralEnquiry from './GeneralEnquiry.vue';
import MediaEnquiry from './MediaEnquiry.vue';
import TicketEnquiry from './TicketEnquiry.vue';
import PartnershipEnquiry from './PartnershipEnquiry.vue';
import ProgramEnquiry from './ProgramEnquiry.vue';

export default {
  name: 'contact-forms',
  components: {
    GeneralEnquiry,
    MediaEnquiry,
    TicketEnquiry,
    PartnershipEnquiry,
    ProgramEnquiry,
    InputSelect,
  },
  data() {
    return {
      selected: 'general',
      formTypes: [
        {
          value: 'general',
          label: 'General',
        },
        {
          value: 'media',
          label: 'Media enquiry',
        },
        {
          value: 'ticketing',
          label: 'Ticketing enquiry',
        },
        {
          value: 'partnership',
          label: 'Partnership - expression of interest',
        },
        {
          value: 'program',
          label: 'Program enquiry',
        },
      ],
      success: false,
      failure: false,
    };
  },
  methods: {
    /**
     * update which form is selected
     */
    selectForm(payload) {
      this.selected = payload;
    },
    /**
     * Display success message when form submitted
     */
    isSuccess() {
      this.success = true;
    },
    /**
     * Display failure message when form submitted incorrectly
     */
    hasFailed() {
      this.failure = true;
    },
  },
  mounted() {
    if (window.location.hash
      && this.formTypes.some(formType => formType.value === window.location.hash.slice(1))
    ) {
      this.selected = window.location.hash.slice(1);
    }
  },
};
</script>
