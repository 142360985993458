// FORMS

const setupInvalidFields = (item) => {
  if (!item) return;

  if (item.classList.contains('is-invalid')) {
    item.addEventListener('click', () => {
      item.classList.remove('is-invalid');
    });
  }
};

const setupSelectFields = (item) => {
  const field = item.getElementsByClassName('js-field_select')[0];
  const select = item.getElementsByTagName('select')[0];
  if (!item || !field || !select) return;

  select.addEventListener('focus', () => {
    field.classList.add('is-focused');
  });

  select.addEventListener('blur', () => {
    field.classList.remove('is-focused');
  });

  item.addEventListener('mouseleave', () => {
    select.blur();
  });
};

const setupMultiselectFields = (item) => {
  const toggle = item.getElementsByClassName('js-field_multiselect_toggle')[0];
  const text = item.getElementsByClassName('js-field_multiselect_text')[0];
  const field = item.getElementsByClassName('js-field_multiselect_fieldset')[0];
  const options = item.getElementsByClassName('js-field_multiselect_option');
  let checked = 0;
  if (!item || !toggle || !text || !field) return;

  toggle.addEventListener('click', (e) => {
    e.preventDefault();
    if (!toggle.classList.contains('is-active')) {
      toggle.classList.add('is-active');
      toggle.classList.add('is-focused');
      toggle.setAttribute('aria-expanded', 'true');
    } else {
      toggle.classList.remove('is-active');
      toggle.classList.remove('is-focused');
      toggle.setAttribute('aria-expanded', 'false');
    }
  });

  const countChecked = () => {
    if (options.length < 1) return;
    checked = 0;
    [].forEach.call(options, (o) => {
      if (o.checked) checked += 1;
    });
  };

  const updateText = () => {
    if (checked < 1) {
      text.textContent = 'Select an option';
    } else if (checked === 1) {
      text.textContent = '1 item selected';
    } else {
      text.textContent = `${checked} items selected`;
    }
  };

  [].forEach.call(options, (option) => {
    option.addEventListener('change', () => {
      countChecked();
      updateText();
    });
  });
};

const setupFileFields = (item) => {
  const field = item.getElementsByClassName('js-field_file')[0];
  const button = item.getElementsByClassName('js-field_file_button')[0];
  const text = item.getElementsByClassName('js-field_file_text')[0];
  if (!item || !field || !button || !text) return;

  button.addEventListener('click', (e) => {
    e.preventDefault();
  });

  field.addEventListener('change', () => {
    if (field.value) {
      text.textContent = field.value;
      field.classList.add('is-active');
    } else {
      text.textContent = 'Upload file here (Max 2mb)';
      field.classList.remove('is-active');
    }
  });
};

const setupFields = () => {
  const items = document.getElementsByClassName('js-field');
  if (!items) return;

  [].forEach.call(items, (item) => {
    setupInvalidFields(item);
    setupSelectFields(item);
    setupMultiselectFields(item);
    setupFileFields(item);
  });
};

const init = () => {
  // FORMS
  setupFields();
};

window.addEventListener('load', init);
