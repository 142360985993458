<template>
  <div
    class="form-item"
    :class="{ 'is-invalid': hasError }"
  >
    <label class="form-label" :for="labelId">{{ label }}</label>
    <fieldset aria-labelledby="radio-label" aria-describedby="radio-label-alert">
      <ul class="form-radios">
        <li class="form-radio" v-for="(field, id) in radioFields" :key="id">
          <input
            type="radio"
            :name="name"
            :value="field.value"
            @input="$emit('input', field.value)"
            :aria-label="ariaLabel"
            :aria-describedby="`${name}-description`"
          >
          <span class="form-radio__icon"></span>
          <label class="form-radio__label">{{ field.label }}</label>
        </li>
      </ul>
    </fieldset>
    <span
      :id="`${name}-description`"
      class="form-alert"
      aria-live="assertive"
      role="alert"
    >{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'fieldset-radio',
  props: {
    name: {
      type: String,
      required: true,
    },
    radioFields: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelId: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
    },
    error: {
      type: String,
    },
  },
};
</script>
