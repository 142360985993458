<template>
  <div class="program-events">
    <expandable-section :id="1" touch-only>
      <div class="program-events__current">
        <div class="columns is-multiline is-centered">
          <div
            v-for="event in currentEvents"
            :key="event.id"
            class="column is-half-tablet is-4-desktop"
          >
            <event-card
              :event-id="event.id"
              :title="event.title"
              :page-url="event.url"
              :genre="event.genre"
              :image="event.thumbnail"
              :dates="event.date_range"
              :performances="event.performances"
              :label="event.label"
              :custom-label="event.custom_label"
              :ticket-button-text="event.ticket_button_text"
            ></event-card>
          </div>
        </div>
      </div>
      <hr />
      <div
        v-if="pastEvents.length"
        class="program-events__past"
      >
        <div class="module module-divider"><hr></div>

        <h4 class="title is-subheading-large has-text-centered event-list-header">Past events</h4>
        <div class="columns is-multiline is-centered">
          <div
            v-for="event in pastEvents"
            :key="event.id"
            class="column is-half-tablet is-4-desktop"
          >
            <event-card
              :event-id="event.id"
              :title="event.title"
              :page-url="event.url"
              :genre="event.genre"
              :image="event.thumbnail"
              :dates="event.date_range"
              :performances="event.performances"
              :label="event.label"
              :custom-label="event.custom_label"
              :ticket-button-text="event.ticket_button_text"
              :in-past="true"
            ></event-card>
          </div>
        </div>
      </div>
    </expandable-section>
  </div>
</template>

<script>
import axios from 'axios';
import EventCard from './EventCard.vue';
import ExpandableSection from '@/components/standard/ExpandableSection.vue';

export default {
  name: 'program-events',
  props: ['pageId'],
  components: {
    EventCard,
    ExpandableSection,
  },
  data() {
    return {
      events: [],
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}pages/?type=events.EventPage&fields=*&child_of=${this.pageId}`)
      .then((response) => {
        this.events = response.data.items;
      });
  },
  computed: {
    /**
     * filtered list of current events
     */
    currentEvents() {
      return this.events.filter(item => !item.in_past);
    },
    /**
     * filterd list of past events
     */
    pastEvents() {
      return this.events.filter(item => item.in_past);
    },
  },
};
</script>
