<template>
  <div class="toggle-buttons">
    <button
      class="toggle-button"
      :class="{'active' : viewMode === 'list'}"
      @click="setViewMode('list')"
    >
      List view
    </button>
    <button
      class="toggle-button"
      :class="{'active' : viewMode === 'full'}"
      @click="setViewMode('full')"
    >
      Full view
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      viewMode: state => state.eventCalendar.viewMode,
    }),
  },
  methods: {
    setViewMode(viewMode) {
      this.$store.commit('eventCalendar/setViewMode', viewMode);
    },
  },
};
</script>
