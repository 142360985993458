<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="toggleModal"></div>
    <div class="modal-content">
      <button class="modal-close is-large" aria-label="close" @click="toggleModal"></button>
      <div class="modal-content__popup rich-text" v-html="message"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'popupmessage-modal',
  props: ['message'],
  data() {
    return { active: false };
  },
  mounted() {
    this.showModal();
  },
  computed: {
    ...mapState({
      newVisitor: state => state.popupmessageModal.newVisitor,
    }),
  },
  methods: {
    showModal() {
      if (this.newVisitor) {
        this.modalTimeout = setTimeout(() => {
          this.toggleModal();
        }, 5000);
        this.$store.commit('popupmessageModal/setNewVisitor');
      }
    },
    toggleModal() {
      this.active = !this.active;
    },
  },
};
</script>
