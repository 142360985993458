<template>
  <div class="container">
    <div class="columns is-desktop">
      <div class="column is-5-desktop is-4-widescreen">
        <div class="event-calendar__sidebar has-background-blue-tint-lighter">
          <div class="event-calendar__sidebar__container">
            <toggle-view />
            <h3 class="has-text-blue has-text-weight-bold">Select a date</h3>
            <v-date-picker
              class="is-hidden-touch"
              is-expanded
              is-inline
              :value="date"
              :attributes="[
                {
                  key: 'selected day',
                  highlight: true,
                  dates: date,
                },
              ]"
              :min-date="new Date()"
              @input="updateDate"
            />
            <v-date-picker
              class="is-hidden-desktop"
              is-expanded
              :value="date"
              :attributes="[
                {
                  key: 'selected day',
                  highlight: true,
                  dates: date,
                },
              ]"
              :min-date="new Date()"
              @input="updateDate"
            >
              <div class="form-item">
                <div class="form-upload">
                  <button class="form-upload__button">
                    <span class="form-upload__text">{{ dateFormatted }}</span>
                    <span class="icon icon--upload"><svg-calendar></svg-calendar></span>
                  </button>
                </div>
              </div>
            </v-date-picker>

            <dropdown-button
              label="Filter results"
              :modal="true"
              :activeFilters="selectedFiltersCount"
              @click="toggleFilterModal"
            />

          </div>
        </div>
        <dropdown-modal
          v-if="filterModalActive"
          :filters="filters"
          :performancesPage="true"
          @toggleModal="toggleFilterModal"
        />
        <div v-if="selectedFiltersCount" class="event-calendar__sidebar__pills">
          <event-filter-pill
            :filter="{
              optionId: 0,
              optionsName: 'Clear all filters'
            }"
            :performancesPage="true"
          />
          <event-filter-pill
            v-for="filter in selectedFilters"
            :key="filter.id"
            :filter="filter"
            :performancesPage="true"
          />
        </div>
      </div>
      <performance-list />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import PerformanceList from '@/components/events/EventCalendar/PerformanceList.vue';
import ToggleView from '@/components/events/EventCalendar/ToggleView.vue';

import DropdownModal from '@/components/events/EventFilter/DropdownModal.vue';
import DropdownButton from '@/components/events/EventFilter/DropdownButton.vue';
import EventFilterPill from '@/components/events/EventFilter/EventFilterPill.vue';

import SvgCalendar from '@/svgs/Calendar.vue';

export default {
  components: {
    PerformanceList,
    ToggleView,
    DropdownModal,
    DropdownButton,
    EventFilterPill,
    SvgCalendar,
  },
  props: {
    eventPageId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$store.commit('eventCalendar/setId', this.eventPageId);
    this.$store.dispatch('eventCalendar/getFilters');
  },
  computed: {
    ...mapState({
      date: state => state.eventCalendar.date,
      filters: state => state.eventCalendar.filters,
      filterModalActive: state => state.eventCalendar.filterModalActive,
    }),
    ...mapGetters({
      selectedFilters: 'eventCalendar/selectedFilters',
      selectedFiltersCount: 'eventCalendar/selectedFiltersCount',
      dateFormatted: 'eventCalendar/dateFormatted',
    }),
  },
  methods: {
    updateDate(event) {
      this.$store.commit('eventCalendar/setDate', event);
    },
    toggleFilterModal() {
      this.$store.commit('eventCalendar/toggleFilterModal');
    },
  },
};
</script>
