<template>
  <headroom :offset="100" :zIndex="100" @pin="setNavActive(true)" @unpin="setNavActive(false)">
    <nav class="nav" :class="{ 'is-active': active }">
      <search></search>
      <div class="nav-container container">
        <div class="search-button__container" :class="{ 'is-active': active }">
          <search-button></search-button>
        </div>
        <div class="nav-logo" :class="{ 'is-active': active }">
          <a href="/">
            <span
              class="nav-logo-animation"
              aria-hidden="true"
              ref="logoanimation"
              @mouseenter="playHoverAnimation"
            ></span>
            <span class="is-sr-only">Geelong Arts Centre</span>
          </a>
        </div>
        <slot name="shortcuts"></slot>
        <div class="nav-icons" :class="{ 'is-active': active }">
          <a
            :href="cartLink"
            class="nav-icons__item nav-icons__item--cart is-hidden-mobile"
            title="view cart"
          >
            <span class="icon icon--cart" aria-hidden="true"><svg-cart></svg-cart></span>
          </a>
          <a
            :href="accountLink"
            class="nav-icons__item nav-icons__item--user is-hidden-mobile"
            title="user login"
          >
            <span class="icon icon--user" aria-hidden="true"><svg-user></svg-user></span>
          </a>
          <burger></burger>
        </div>
      </div>
      <navigation
        :cartLink="cartLink"
        :accountLink="accountLink"
        :contactNumber="contactNumber"
        :facebookLink="facebookLink"
        :twitterLink="twitterLink"
        :instagramLink="instagramLink"
        :privacyLink="privacyLink"
        :legalLink="legalLink"
      >
        <template v-slot:main>
          <slot name="main"></slot>
        </template>
        <template v-slot:child>
          <slot name="child"></slot>
        </template>
      </navigation>
      <div class="overlay--nav" @click="hideMenu()"></div>
    </nav>
  </headroom>
</template>


<script>
import { mapState } from 'vuex';
import lottie from 'lottie-web/build/player/lottie_light';
import { headroom } from 'vue-headroom';

import Burger from '@/components/nav/Burger.vue';
import Navigation from '@/components/nav/Navigation.vue';
import Search from '@/components/nav/Search.vue';
import SearchButton from '@/components/nav/SearchButton.vue';
import SvgCart from '@/svgs/Cart.vue';
import SvgLogoAnimationIntro from '@/animations/GAC-Logo-FA-1a.json';
import SvgLogoAnimationHover from '@/animations/GAC-Logo-FA-2a.json';
import SvgUser from '@/svgs/User.vue';

export default {
  name: 'nav-container',
  props: {
    cartLink: String,
    accountLink: String,
    contactNumber: String,
    facebookLink: String,
    twitterLink: String,
    instagramLink: String,
    privacyLink: String,
    legalLink: String,
  },
  components: {
    Burger,
    headroom,
    Navigation,
    Search,
    SearchButton,
    SvgCart,
    SvgUser,
  },
  computed: {
    ...mapState({
      active: state => state.menu.active,
      firstLoad: state => state.menu.firstLoad,
    }),
    playIntroAnimation() {
      return this.firstLoad || window.location.pathname === '/';
    },
  },
  mounted() {
    if (this.playIntroAnimation) {
      this.navLogoAnimation = lottie.loadAnimation({
        animationData: SvgLogoAnimationIntro,
        autoplay: true,
        container: this.$refs.logoanimation,
        loop: false,
        name: 'logo-animation',
        renderer: 'svg',
      });
      this.navLogoAnimation.addEventListener('complete', () => {
        this.navLogoAnimation.destroy();
        this.loadHoverAnimation();
      });
      this.$store.commit('menu/siteFirstLoaded');
    } else {
      this.loadHoverAnimation();
    }
  },
  methods: {
    hideMenu() {
      this.$store.commit('menu/setMenuActive', false);
    },
    playHoverAnimation() {
      this.navLogoAnimation.goToAndPlay(1, true, 'logo-animation');
    },
    loadHoverAnimation() {
      this.navLogoAnimation = lottie.loadAnimation({
        animationData: SvgLogoAnimationHover,
        autoplay: false,
        container: this.$refs.logoanimation,
        loop: false,
        name: 'logo-animation',
        renderer: 'svg',
      });
    },
    setNavActive(payload) {
      this.$store.commit('menu/setNavActive', payload);
    },
  },
};
</script>
