<template>
  <div class="images">
    <div v-for="i in itemCount" :key="i">
      <transition name="background-image">
        <div v-if="activeImage === i">
          <slot :name="`item-${i}`"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'background-image-slider',
  props: {
    itemCount: Number,
  },
  data() {
    return {
      activeImage: 1,
    };
  },
  mounted() {
    this.imageChangeTimeout();
  },
  destroyed() {
    clearTimeout(this.imageTimeout);
  },
  methods: {
    imageChangeTimeout() {
      this.imageTimeout = setTimeout(() => {
        this.nextImage();
        this.imageChangeTimeout();
      }, 5000);
    },
    nextImage() {
      if (this.activeImage < this.itemCount) {
        this.activeImage = this.activeImage + 1;
      } else {
        this.activeImage = 1;
      }
    },
  },
};
</script>
