<template>
  <div class="flickity-carousel">
    <flickity
      class="carousel"
      :class="classes"
      ref="flickity"
      :options="{
        wrapAround: wrapAround,
        groupCells: '108%',
        cellAlign: cellAlign,
        percentPosition: false,
        arrowShape: 'M72 77.2 64.833 84 29 50 64.833 16 72 22.8 43.557 50z',
        autoPlay: autoPlay,
        setGallerySize: setGallerySize,
        adaptiveHeight: false,
        prevNextButtons: prevNextButtons,
        pageDots: showDots,
      }"
    >
      <slot></slot>
    </flickity>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Flickity from 'vue-flickity';

export default {
  props: {
    autoPlay: {
      default: false,
    },
    size: {
      type: String,
      default: 'full',
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    wrapAround: {
      type: Boolean,
      default: false,
    },
    pageDots: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Flickity,
  },
  data() {
    return {
      dotsNeeded: true,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      if (this.$refs.flickity.slides().length < 2) {
        this.dotsNeeded = false;
      } else {
        this.dotsNeeded = true;
      }
      this.$nextTick(() => {
        this.$refs.flickity.rerender();
      });
    },
  },
  computed: {
    classes() {
      return `${this.type} ${this.size} ${this.isFull ? 'is-full' : ''} ${this.compact ? 'is-compact' : ''}`;
    },
    setGallerySize() {
      return this.type !== 'image' || this.type !== 'programs';
    },
    cellAlign() {
      return this.type === 'quote' ? 'center' : 'left';
    },
    prevNextButtons() {
      return this.type !== 'quote';
    },
    showDots() {
      return this.dotsNeeded && this.pageDots;
    },
  },
};
</script>
