/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const state = {
  navActive: true,
  visible: false,
  active: false,
  search: false,
  activeChild: [],
  prevChild: null,
  firstLoad: true,
};

const mutations = {
  /**
   * record whether the nav is visible when scrolling
   * @param {object} state
   */
  setNavActive(state, payload) {
    state.navActive = payload;
  },
  /**
   * toggle whether menu is display: none
   * @param {object} state
   */
  setMenuVisible(state, payload) {
    state.visible = payload;
  },
  /**
   * toggle the active state of the menu
   * @param {object} state
   */
  setMenuActive(state, payload) {
    state.active = payload;
  },
  /**
   * Toggle the state of the searchbar
   * @param {object} state
   */
  toggleSearch(state) {
    state.search = !state.search;
  },
  /**
   * Which child menu is active.
   */
  activateChild(state, menuId) {
    state.activeChild.push(menuId);
  },
  /**
   * Which child menu is active.
   */
  activateParent(state) {
    state.activeChild.pop();
  },
  /**
   * On site first load set to false.
   */
  siteFirstLoaded(state) {
    state.firstLoad = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
