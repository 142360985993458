<template>
  <div
    class="form-item"
    :class="{ 'is-invalid': hasError, 'is-paddingless': expander }"
  >
    <label class="form-label">{{ label }} &nbsp;</label>
    <div class="input-container">
      <textarea
        class="textarea"
        :name="name"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :aria-label="ariaLabel"
        :aria-describedby="`${name}-description`"
        :placeholder="placeholder"
        @focus="$emit('focused')"
      ></textarea>
    </div>
    <span
      :id="`${name}-description`"
      class="form-alert"
      aria-live="assertive"
      role="alert"
    >{{ error }}</span>
  </div>
</template>

<script>

export default {
  name: 'input-textarea',
  props: {
    value: {
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    expander: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
    },
    error: {
      type: String,
    },
  },
};
</script>
