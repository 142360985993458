<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#3A37CC" d="M13.77 7.211h-1.481a.23.23 0 0 0-.232.23v4.617H1.943V1.972h4.451a.231.231 0 0 0 .231-.231V.26a.231.231 0 0 0-.23-.231H.23a.232.232 0 0 0-.232.232V13.77c0 .127.104.23.232.23h13.54a.23.23 0 0 0 .23-.231V7.441a.23.23 0 0 0-.23-.23"/><path fill="#3A37CC" d="M10.23 2.408L6.176 6.463a.249.249 0 0 0 0 .353l1.008 1.007a.249.249 0 0 0 .353 0l4.054-4.055 1.415 1.414c.208.21.567.08.592-.215l.164-1.878.236-2.71A.348.348 0 0 0 13.621 0l-2.71.236L9.034.4a.348.348 0 0 0-.216.593l1.412 1.414z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-external',
};
</script>
