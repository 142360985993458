<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" fill-rule="evenodd" d="M1.538 20h16.924V7.692H1.538V20zM6.154 5.385V1.923a.374.374 0 0 0-.108-.276.374.374 0 0 0-.277-.109H5a.374.374 0 0 0-.276.109.374.374 0 0 0-.109.276v3.462a.37.37 0 0 0 .109.276A.374.374 0 0 0 5 5.77h.77a.374.374 0 0 0 .276-.108.374.374 0 0 0 .108-.276zm9.23 0V1.923a.374.374 0 0 0-.108-.276.374.374 0 0 0-.276-.109h-.77a.374.374 0 0 0-.276.109.374.374 0 0 0-.108.276v3.462c0 .112.036.204.108.276a.374.374 0 0 0 .277.108H15a.374.374 0 0 0 .276-.108.374.374 0 0 0 .109-.276zM20 4.615V20c0 .417-.152.777-.457 1.082a1.478 1.478 0 0 1-1.081.456H1.538c-.416 0-.777-.152-1.081-.456A1.478 1.478 0 0 1 0 20V4.615c0-.416.152-.777.457-1.081a1.478 1.478 0 0 1 1.081-.457h1.539V1.923c0-.529.188-.981.565-1.358A1.85 1.85 0 0 1 5 0h.77c.528 0 .98.188 1.357.565.377.377.565.83.565 1.358v1.154h4.616V1.923c0-.529.188-.981.565-1.358A1.848 1.848 0 0 1 14.23 0H15c.529 0 .982.188 1.358.565.377.377.565.83.565 1.358v1.154h1.539c.416 0 .777.152 1.081.457.305.304.457.665.457 1.081z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-calendar',
};
</script>
