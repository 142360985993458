<template>
  <div class="module has-background-blue-tint-lighter">
    <div class="container form-container">
      <div class="columns is-center-stacked">
        <div class="column is-8">
          <div class="form__intro" v-if="!submitError">
            <slot name="initial" v-if="!completed"></slot>
            <slot name="complete" v-if="completed"></slot>
          </div>
          <div class="form__intro content" v-if="submitError" v-html="submitError"></div>
        </div>

        <div class="column is-6-tablet is-4-desktop">
          <form v-if="!completed">
            <input-text
              v-model="formValues.email"
              name="email"
              :label="expanded ? 'Email*' : ''"
              type="email"
              aria-label="Your email address"
              placeholder="Email address*"
              v-validate="'required|email'"
              :expander="!expanded"
              @focused="expandForm"
              :has-error="errors.has('email')"
              :error="errors.first('email')"
            />

            <div v-if="expanded">
              <input-text
                v-model="formValues.firstName"
                name="first-name"
                label="First name*"
                type="text"
                aria-label="Your first name"
                placeholder=""
                v-validate="'required|alpha'"
                :has-error="errors.has('first-name')"
                :error="errors.first('first-name')"
              />
              <input-text
                v-model="formValues.lastName"
                name="last-name"
                label="Last name"
                type="text"
                aria-label="Your last name"
                placeholder=""
                v-validate="'alpha'"
                :has-error="errors.has('last-name')"
                :error="errors.first('last-name')"
              />
              <input-text
                v-model="formValues.mobile"
                name="mobile"
                label="Mobile"
                type="text"
                aria-label="Your mobile phone number"
                placeholder=""
                v-validate="'numeric|min:10|max:10'"
                :has-error="errors.has('mobile')"
                :error="errors.first('mobile')"
              />
              <input-text
                v-model="formValues.postcode"
                name="post-code"
                label="Postcode"
                type="text"
                aria-label="Your post code"
                placeholder=""
                v-validate="'numeric|min:4|max:4'"
                :has-error="errors.has('post-code')"
                :error="errors.first('post-code')"
              />
              <div class="form-item" :class="{'is-invalid': errors.has('terms')}">
                <fieldset aria-labelledby="checkbox-label" aria-describedby="checkbox-label-alert">
                  <ul class="form-checkboxes">
                    <li class="form-checkbox">
                      <input
                        v-model="formValues.terms"
                        v-validate="'required:true'"
                        type="checkbox"
                        name="terms"
                        id="checkbox-option-1"
                      >
                      <span class="form-checkbox__icon"></span>
                      <label class="form-checkbox__label" for="checkbox-option-1">
                        Yes, I've read the Geelong Arts Centre's
                        <a href="https://geelongartscentre.org.au/about-us/legal/privacy-policy/"
                        target="_blank">privacy policy</a></label>
                    </li>
                  </ul>
                </fieldset>
                <span
                  id="checkbox-label-alert"
                  class="form-alert"
                  aria-live="assertive"
                  role="alert"
                >{{ errors.first('terms') }}</span>
              </div>

              <button
                id="subscribe-form-submit-button"
                class="button is-fullwidth"
                @click.prevent="submitForm"
              >
                <span>Subscribe</span>
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import InputText from './elements/InputText.vue';

export default {
  name: 'sign-up-form',
  components: {
    InputText,
  },
  data() {
    return {
      expanded: false,
      submitError: '',
      formValues: {
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        postcode: '',
        terms: false,
      },
      completed: false,
    };
  },
  methods: {
    expandForm() {
      this.expanded = true;
    },
    submitForm() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          const csrf = Cookies.get('csrftoken');

          axios.post('/mailchimp/subscribe/', {
            email: this.formValues.email,
            first_name: this.formValues.firstName,
            second_name: this.formValues.lastName,
            mobile: this.formValues.mobile,
            postcode: this.formValues.postcode,
          }, { headers: { 'X-CSRFToken': csrf } })
            .then(() => {
              this.completed = true;
            })
            .catch((error) => {
              this.submitError = `<p>There was an error subscribing you. The error says: "${error.response.data.errors.title}".</p>
              <p>If that doesn't make much sense to you, feel free to get in touch.</p>`;
              this.expanded = false;
            });
        }
      });
    },
  },
};
</script>
