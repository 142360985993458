<template>
  <div v-if="visible" class="nav-menu container" :class="{ 'is-active ': active }">
    <div class="nav-menu__container" @transitionend="checkMenuVisibility">
      <div
        class="nav-menu__wrap"
        :class="{ 'is-active ': active && !childActive }"
        id="main-menu"
        :aria-expanded="ariaExpanded"
      >
        <ul class="nav-menu__list">
          <slot name="main"></slot>
        </ul>

        <ul class="nav-account is-hidden-desktop">
          <li class="nav-account__item">
            <a :href="cartLink" class="nav-account__link">
              <span class="icon icon--cart" aria-hidden="true"><svg-cart></svg-cart></span>
              <span class="is-small">Cart</span>
            </a>
          </li>
          <li class="nav-account__item">
            <a :href="accountLink" class="nav-account__link">
              <span class="icon icon--user" aria-hidden="true"><svg-user></svg-user></span>
              <span class="is-small">Sign in</span>
            </a>
          </li>
        </ul>

        <p class="nav-phone is-large">
          <span class="icon icon--phone" aria-hidden="true"><svg-phone></svg-phone></span>
          <span>Box office <strong>{{ contactNumber }}</strong></span>
        </p>

        <ul class="nav-socials is-hidden-desktop">
          <li class="nav-socials__item">
            <a :href="facebookLink" aria-label="go to our Facebook">
              <span class="icon icon--social-nav" aria-hidden="true">
                <svg-social-facebook></svg-social-facebook>
              </span>
            </a>
          </li>
          <li class="nav-socials__item">
            <a :href="twitterLink" aria-label="go to our Twitter">
              <span class="icon icon--social-nav" aria-hidden="true">
                <svg-social-twitter></svg-social-twitter>
              </span>
            </a>
          </li>
          <li class="nav-socials__item">
            <a :href="instagramLink" aria-label="go to our Instagram">
              <span class="icon icon--social-nav" aria-hidden="true">
                <svg-social-instagram></svg-social-instagram>
              </span>
            </a>
          </li>
        </ul>

        <ul class="nav-aux is-hidden-desktop">
          <li class="nav-aux__item"><a :href="privacyLink">Privacy policy</a></li>
          <li class="nav-aux__item"><a :href="legalLink">Legal</a></li>
        </ul>
      </div>
      <slot name="child"></slot>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapState } from 'vuex';
import SvgUser from '@/svgs/User.vue';
import SvgCart from '@/svgs/Cart.vue';
import SvgPhone from '@/svgs/Phone.vue';
import SvgSocialFacebook from '@/svgs/SocialFacebook.vue';
import SvgSocialInstagram from '@/svgs/SocialInstagram.vue';
import SvgSocialTwitter from '@/svgs/SocialTwitter.vue';

export default {
  name: 'navigation',
  components: {
    SvgUser,
    SvgCart,
    SvgPhone,
    SvgSocialFacebook,
    SvgSocialInstagram,
    SvgSocialTwitter,
  },
  props: {
    cartLink: String,
    accountLink: String,
    contactNumber: String,
    facebookLink: String,
    twitterLink: String,
    instagramLink: String,
    privacyLink: String,
    legalLink: String,
  },
  computed: {
    ariaExpanded() {
      return this.active ? 'true' : 'false';
    },
    childActive() {
      return this.activeChild.length > 0;
    },
    ...mapState({
      visible: state => state.menu.visible,
      active: state => state.menu.active,
      activeChild: state => state.menu.activeChild,
    }),
  },
  methods: {
    checkMenuVisibility() {
      if (!this.active && this.visible) {
        this.$store.commit('menu/setMenuVisible', false);
      }
    },
  },
};
</script>
