<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" d="M10.1663359,1.0653656 C11.0420896,0.527586764 11.8996879,0.25 12.7352883,0.25 C14.2525683,0.25 15.4852526,0.683205487 16.3904188,1.56294014 C17.2998073,2.44712398 17.75,3.65530586 17.75,5.13937289 C17.75,6.18126196 17.3862664,7.22508797 16.6825314,8.25668868 C16.3378041,8.77273407 15.9392509,9.2458628 15.4918188,9.67121824 L9.95834474,15.0151966 C9.68607732,15.2482543 9.3565824,15.3716216 9.00014375,15.3716216 C8.64349443,15.3716216 8.3139803,15.2483037 8.00826206,14.9848243 L2.53349831,9.69457706 C2.1504955,9.35714878 1.76879586,8.91088959 1.38402619,8.35632365 C0.635223316,7.27881902 0.25,6.20444884 0.25,5.13937289 C0.25,3.65530586 0.700192703,2.44712398 1.60958116,1.56294014 C2.51474744,0.683205487 3.74743175,0.25 5.2647117,0.25 C6.10046387,0.25 6.95808933,0.527595564 7.82308291,1.05898735 C8.22280433,1.29546863 8.61506199,1.57581058 9.00004761,1.89960707 C9.38410938,1.57639206 9.77397828,1.29747322 10.1663359,1.0653656 Z M12.7352883,1.75 C12.1986746,1.75 11.6049947,1.94216185 10.9407635,2.34993225 C10.462714,2.63283037 9.98868675,2.99886185 9.51950014,3.44921022 L9.00021696,3.94764372 L8.48086359,3.44928337 C8.01137725,2.99877437 7.5373384,2.63278459 7.04867858,2.34356655 C6.3951138,1.94215305 5.80146114,1.75 5.2647117,1.75 C4.11563692,1.75 3.2598793,2.05074115 2.65512782,2.63850147 C2.05457534,3.22240904 1.75,4.0397977 1.75,5.13937289 C1.75,5.87471632 2.03129876,6.65924568 2.61611734,7.50078225 C2.93675874,7.96292024 3.24160266,8.31932457 3.55039605,8.59242808 L8.99902586,13.85821 L14.4539398,8.58827264 C14.8253791,8.23513297 15.1522204,7.84713449 15.439304,7.41744341 C15.98637,6.61545397 16.25,5.85890089 16.25,5.13937289 C16.25,4.03982977 15.9454424,3.22245671 15.3449247,2.63855255 C14.7400711,2.05072373 13.8843298,1.75 12.7352883,1.75 Z"/>
    <!-- eslint-disable-next-line max-len -->
    <path class="heartfill" fill-opacity="0" fill="#06038D" fill-rule="evenodd" d="M12.735 1c1.334 0 2.378.367 3.133 1.1C16.623 2.836 17 3.849 17 5.14c0 .88-.314 1.78-.941 2.7-.314.47-.677.9-1.088 1.292l-5.5 5.313a.703.703 0 0 1-.47.177.703.703 0 0 1-.472-.177l-5.5-5.313c-.333-.294-.676-.695-1.03-1.204C1.334 6.97 1 6.04 1 5.14c0-1.291.377-2.304 1.132-3.038C2.887 1.367 3.932 1 5.265 1c.686 0 1.412.235 2.176.704.53.314 1.05.715 1.56 1.204a8.549 8.549 0 0 1 1.558-1.204c.765-.47 1.49-.704 2.176-.704"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-heart-simple',
};
</script>
