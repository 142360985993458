<template>
  <div class="shortlist-sidebar" :class="{'is-active' : shortlistSidebarActive}">
    <transition name="fade">
      <div v-if="totalSelected > 0" class="shortlist-sidebar__tab">
        <transition name="fade">
          <div v-if="alertVisible" class="shortlist-sidebar__alert">
            <span class="icon icon--check shortlist-sidebar__alert__check"><svg-check/></span>
            <span><em>{{ lastEventTitleAdded }}</em> added to shortlist</span>
          </div>
        </transition>
        <button class="shortlist-sidebar__tab__button is-filled" @click="toggleSidebar">
          <p class="is-meta has-text-weight-bold is-family-monospace">{{ totalSelected }}</p>
          <span class="icon icon--heart-shortlist">
            <svg-heart-simple class="shortlist-sidebar-heart"/>
          </span>
        </button>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="shortlistSidebarActive"
        class="shortlist-sidebar__background"
        @click="closeSidebar"
      ></div>
    </transition>
    <div class="shortlist-sidebar__list">
      <button class="shortlist-sidebar__close" @click="closeSidebar">
        <span class="icon icons--close"><svg-close/></span>
      </button>
      <h6 class="title is-6 has-text-weight-bold has-text-blue">Event shortlist</h6>
      <div class="shortlist-sidebar__list__links">
        <p class="is-meta is-family-monospace has-text-weight-bold has-text-blue is-uppercase">
          Share your shortlist
        </p>
        <a :href="shortlistUrl" class="button has-background-blue-tint-lighter is-cta">
          <span class="icon icon--external"><svg-external/></span>
          <span class="button-cta-text">Link to shortlist</span>
        </a>
        <br>
        <button
          class="button has-background-blue-tint-lighter is-cta is-paddingless"
          v-clipboard:copy="shortlistUrl"
          v-clipboard:success="onCopy"
        >
          <span class="icon icon--link"><svg-link/></span>
          <span class="button-cta-text">Copy link</span>
          <transition name="fade">
            <span v-if="showCopiedLinkMessage" class="link-copied-message has-text-weight-normal">
              <span>Copied!</span>
              <span class="icon icon--check"><svg-check/></span>
            </span>
          </transition>
        </button>
      </div>
      <div class="columns is-multiline">
        <div v-for="event in shortlistEvents" :key="event" class="column is-full">
          <event-card :eventId="event"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EventCard from '@/components/events/EventCard.vue';
import SvgCheck from '@/svgs/Check.vue';
import SvgClose from '@/svgs/Close.vue';
import SvgExternal from '@/svgs/External.vue';
import SvgHeartSimple from '@/svgs/HeartSimple.vue';
import SvgLink from '@/svgs/Link.vue';

export default {
  components: {
    EventCard,
    SvgCheck,
    SvgClose,
    SvgExternal,
    SvgHeartSimple,
    SvgLink,
  },
  data() {
    return {
      alertVisible: false,
      showCopiedLinkMessage: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('shortlist/toggleSidebar');
    },
    closeSidebar() {
      this.$store.commit('shortlist/closeSidebar');
    },
    onCopy() {
      clearTimeout(this.hideCopiedLinkMessageTimeout);
      this.showCopiedLinkMessage = true;
      this.hideCopiedLinkMessageTimeout = setTimeout(() => {
        this.showCopiedLinkMessage = false;
      }, 4000);
    },
  },
  computed: {
    ...mapState({
      shortlistEvents: state => state.shortlist.shortlistEvents,
      lastEventTitleAdded: state => state.shortlist.lastEventTitleAdded,
      shortlistSidebarActive: state => state.shortlist.shortlistSidebarActive,
    }),
    totalSelected() {
      return this.shortlistEvents.length;
    },
    shortlistUrl() {
      const arrayOfIds = this.shortlistEvents.join();
      return `${process.env.VUE_APP_BASE_URL}shortlist/?events=${arrayOfIds}`;
    },
  },
  watch: {
    lastEventTitleAdded(newTitle) {
      if (newTitle === '') {
        clearTimeout(this.hideAlertTimeout);
        this.alertVisible = false;
      } else {
        clearTimeout(this.hideAlertTimeout);
        this.alertVisible = true;
        this.hideAlertTimeout = setTimeout(() => {
          this.alertVisible = false;
        }, 4000);
      }
    },
    totalSelected(newAmount) {
      if (newAmount < 1) {
        this.closeSidebar();
      }
    },
  },
};
</script>
