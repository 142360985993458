<template>
  <div class="general-enquiry">
    <input-text
      name="name"
      label="Name *"
      v-model="name"
      @input="updateName"
      v-validate="'required'"
      :has-error="errors.has('name')"
      :error="errors.first('name')"
      ariaLabel="general-name"
    ></input-text>
    <input-text
      name="business_name"
      label="Business name"
      @input="updateBusinessName"
      ariaLabel="business-name"
    ></input-text>
    <input-text
      name="business_type"
      label="Business type"
      @input="updateBusinessType"
      ariaLabel="business-type"
    ></input-text>
    <input-text
      name="email"
      label="Email *"
      v-model="email"
      @input="updateEmail"
      v-validate="'required|email'"
      :has-error="errors.has('email')"
      :error="errors.first('email')"
      type="email"
      ariaLabel="general-email"
    ></input-text>
    <input-text
      name="phone_number"
      label="Phone Number"
      v-model="phone"
      @input="updatePhone"
      v-validate="{
        required: false,
        // eslint-disable-next-line
        regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/
      }"
      :has-error="errors.has('phone_number')"
      :error="errors.first('phone_number')"
      ariaLabel="general-phone"
    ></input-text>
    <p>A member of our team will contact you shortly to discuss partnership opportunities. If you
      have any specific questions you’d liked answered please let us know.<br><br></p>
    <input-textarea
      name="questions"
      label="Any questions?"
      @input="updateQuestion"
      ariaLabel="general-questions"
    ></input-textarea>
    <button class="button" @click="submit">Send</button>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import InputText from './elements/InputText.vue';
import InputTextarea from './elements/InputTextarea.vue';

export default {
  name: 'general-enquiry',
  components: { InputText, InputTextarea },
  data() {
    return {
      name: '',
      business_name: '',
      business_type: '',
      email: '',
      phone: '',
      questions: '',
    };
  },
  methods: {
    /**
     * Update name field for submission
     */
    updateName(data) {
      this.name = data;
    },
    /**
     * Update business name field for submission
     */
    updateBusinessName(data) {
      this.business_name = data;
    },
    /**
     * Update business type field for submission
     */
    updateBusinessType(data) {
      this.business_type = data;
    },
    /**
     * Update email field for submission
     */
    updateEmail(data) {
      this.email = data;
    },
    /**
     * Update phone field for submission
     */
    updatePhone(data) {
      this.phone = data;
    },
    /**
     * Update questions field for submission
     */
    updateQuestion(data) {
      this.questions = data;
    },
    /**
     * Validate then submit form
     */
    submit() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          const csrf = Cookies.get('csrftoken');

          axios.post('', {
            name: this.name,
            business_name: this.business_name,
            business_type: this.business_type,
            email: this.email,
            phone: this.phone,
            questions: this.questions,
            request_type: 'partnership',
          }, { headers: { 'X-CSRFToken': csrf } })
            .then(() => {
              this.$emit('success');
            })
            .catch(() => {
              this.$emit('failure');
            });
        }
      });
    },
  },
};
</script>
