<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <!-- eslint-disable-next-line max-len -->
    <path d="M1.636.222l14.142 14.142-1.414 1.414L.222 1.636z" fill="#06038D"/><path d="M.222 14.364L14.364.222l1.414 1.414L1.636 15.778z" fill="#06038D"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-close',
};
</script>
