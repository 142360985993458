<template>
  <div>
      <button
        v-for="item in items"
        :key="item.id"
        @click="setOrder({
          id: item.id,
          label: item.label,
        })"
        class="form-dropdown__item"
        :class="{ 'is-active' : selectedSortOrder.id === item.id }"
      >
        {{ item.label }}
      </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      selectedSortOrder: state => state.eventFilter.selectedSortOrder,
    }),
  },
  methods: {
    setOrder(order) {
      this.$store.dispatch('eventFilter/setSelectedSortOrder', order);
    },
  },
};
</script>
