<template>
  <div class="modal" :class="{ 'is-active' : performances.length > 0 }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <div class="card-event__modal">
        <performance-list :title="eventTitle" :event-link="eventLink">
          <div
            class="event-performance__item"
            v-for="performance in performances"
            :key="performance.id"
          >
            <event-performance v-if="!performance.isPast"
              :perfId="performance.performanceId"
              :perfTime="performance.formatted_date"
              :purchaseLink="performance.purchaseLink"
              :waitlistLink="performance.waitlistLink"
            ></event-performance>
          </div>
        </performance-list>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EventPerformance from '@/components/events/EventPerformance.vue';
import PerformanceList from '@/components/events/PerformanceList.vue';

export default {
  name: 'performance-modal',
  computed: {
    ...mapState({
      performances: state => state.performanceModal.performances,
      eventTitle: state => state.performanceModal.eventTitle,
      eventLink: state => state.performanceModal.eventLink,
    }),
  },
  components: { EventPerformance, PerformanceList },
  methods: {
    /**
     * close modal by unsetting performances in state
     */
    closeModal() {
      this.$store.commit('performanceModal/clearPerformances');
    },
  },
};
</script>
