<template>
  <div class="has-text-centered">
    <div class="load-more-animation" aria-hidden="true" ref="loadMoreAnimation"></div>
    <div class="has-text-blue">Loading more...</div>
  </div>
</template>

<script>
import lottie from 'lottie-web/build/player/lottie_light';
import SvgLogoAnimation from '@/animations/GAC-Logo-FA-4.json';

export default {
  mounted() {
    lottie.loadAnimation({
      animationData: SvgLogoAnimation,
      autoplay: true,
      container: this.$refs.loadMoreAnimation,
      loop: true,
      name: 'load-more-animation',
      renderer: 'svg',
    });
  },
};
</script>
