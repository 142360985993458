<template>
  <div
    class="nav-search"
    :class="{ 'is-active': search }"
    id="nav-search-bar"
    aria-expanded="false">
    <form class="nav-search__container container" action="/search/" method="GET">
      <input
        class="nav-search__input" name="query" type="text" placeholder="Search…"
        aria-label="search site">
      <button class="nav-search__submit" type="submit" title="submit search">
        <div class="icon icon--search" aria-hidden="true"><svg-search></svg-search></div>
      </button>
      <button
        @click.prevent="toggleSearch()"
        class="nav-search__close"
        title="close search and nav bar"
        aria-controls="nav-search-bar">
        <span class="is-meta is-family-monospace is-hidden-touch"><b>Close</b></span>
        <span class="icon icon--close" aria-hidden="true"><svg-close></svg-close></span>
      </button>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapState } from 'vuex';
import SvgSearch from '@/svgs/Search.vue';
import SvgClose from '@/svgs/Close.vue';

export default {
  name: 'search',
  components: { SvgSearch, SvgClose },
  computed: {
    ...mapState({
      search: state => state.menu.search,
    }),
  },
  methods: {
    /**
     * Toggle search visibility
     */
    toggleSearch() {
      this.$store.commit('menu/toggleSearch');
    },
  },
};
</script>
