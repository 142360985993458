<template>
  <div class="modal modal--dropdown is-active">
    <div class="modal-background" @click="$emit('toggleModal')"></div>
    <div class="modal-content">
      <h3 class="title is-5 has-text-blue">Filter results</h3>
      <event-filter-multi-select
        v-for="filter in filters"
        :key="filter.id"
        :label="filter.name"
        :items="filter.options"
        :performancesPage="performancesPage"
      />
      <input-checkbox
        v-if="!performancesPage"
        name="past_events"
        label="Show only past events"
        :value="showPastEvents"
        @input="toggleShowPastEvents"
      />
      <button class="button is-regular is-fullwidth" @click="$emit('toggleModal')">
        Apply filters
      </button>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="$emit('toggleModal')">
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EventFilterMultiSelect from '@/components/events/EventFilter/EventFilterMultiSelect.vue';
import InputCheckbox from '@/components/forms/elements/InputCheckbox.vue';

export default {
  props: ['filters', 'performancesPage'],
  components: {
    EventFilterMultiSelect,
    InputCheckbox,
  },
  mounted() {
    document.addEventListener('keydown', this.checkKeyPress);
  },
  destroyed() {
    document.removeEventListener('keydown', this.checkKeyPress);
  },
  methods: {
    toggleShowPastEvents() {
      this.$store.dispatch('eventFilter/toggleShowPastEvents');
    },
    /**
     * Check key pressed is 'escape' if modal is active
     */
    checkKeyPress(e) {
      if (this.$store.state.eventFilter.filterModalActive) {
        const event = e || window.event;
        if (event.keyCode === 27 || event.key === 'Escape' || event.key === 'Esc') {
          this.$emit('toggleModal');
        }
      }
    },
  },
  computed: {
    ...mapState({
      showPastEvents: state => state.eventFilter.showPastEvents,
    }),
  },
};
</script>
