<template>
  <div class="general-enquiry">
    <input-text
      name="name"
      label="Name *"
      v-model="name"
      @input="updateName"
      v-validate="'required'"
      :has-error="errors.has('name')"
      :error="errors.first('name')"
      ariaLabel="general-name"
    ></input-text>
    <input-text
      name="email"
      label="Email *"
      v-model="email"
      @input="updateEmail"
      v-validate="'required|email'"
      :has-error="errors.has('email')"
      :error="errors.first('email')"
      type="email"
      ariaLabel="general-email"
    ></input-text>
    <input-text
      name="phone_number"
      label="Phone Number"
      v-model="phone"
      @input="updatePhone"
      v-validate="{
        required: false,
        // eslint-disable-next-line
        regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/
      }"
      :has-error="errors.has('phone_number')"
      :error="errors.first('phone_number')"
      ariaLabel="general-phone"
    ></input-text>
    <input-textarea
      name="description"
      label="Description"
      v-model="description"
      @input="updateDesc"
      :has-error="errors.has('description')"
      :error="errors.first('description')"
      ariaLabel="general-description"
    ></input-textarea>
    <button class="button" @click="submit">Send</button>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import InputText from './elements/InputText.vue';
import InputTextarea from './elements/InputTextarea.vue';

export default {
  name: 'general-enquiry',
  components: { InputText, InputTextarea },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      description: '',
    };
  },
  methods: {
    /**
     * Update name field for submission
     */
    updateName(data) {
      this.name = data;
    },
    /**
     * Update email field for submission
     */
    updateEmail(data) {
      this.email = data;
    },
    /**
     * Update phone field for submission
     */
    updatePhone(data) {
      this.phone = data;
    },
    /**
     * Update description field for submission
     */
    updateDesc(data) {
      this.description = data;
    },
    /**
     * Validate then submit form
     */
    submit() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          const csrf = Cookies.get('csrftoken');

          axios.post('', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            description: this.description,
            request_type: 'general',
          }, { headers: { 'X-CSRFToken': csrf } })
            .then(() => {
              this.$emit('success');
            })
            .catch(() => {
              this.$emit('failure');
            });
        }
      });
    },
  },
};
</script>
