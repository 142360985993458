<template>
  <div
    :id="slug"
    class="expandable-section__container"
    :class="{ 'no-border-desktop': touchOnly }"
  >
    <div
      class="expandable-section"
      :class="{ 'is-collapsed': !expanded, 'is-collapsed-touch-only': touchOnly }"
      ref="content"
    >
      <h3 v-if="title" class="title is-subheading-large">{{ title }}</h3>
      <slot></slot>
    </div>
    <button
      class="expandable-section__button"
      :class="{ 'is-active': expanded, 'is-hidden-desktop': touchOnly }"
      @click="toggleExpanded(!expanded)"
    >
      <span>{{ buttonText }}</span>
      <span class="icon icon--arrow"><arrow-svg></arrow-svg></span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ArrowSvg from '@/svgs/Arrow.vue';

export default {
  name: 'expandable-section',
  components: { ArrowSvg },
  props: {
    id: Number,
    title: String,
    slug: String,
    touchOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonText() {
      return this.expanded ? 'Show less' : 'Show more';
    },
    ...mapState({
      expanded(state) {
        return state.expandableSections.activeSection === this.slug;
      },
    }),
  },
  methods: {
    /**
     * Toggle the expanded state of the expandable content
     */
    toggleExpanded(status) {
      const newSlug = status ? this.slug : null;
      this.$store.commit('expandableSections/setActiveSection', newSlug);
    },
  },
};
</script>
