/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios';
import moment from 'moment';

const state = {
  id: 0,
  filters: [],
  filterModalActive: false,
  date: new Date(),
  performances: [],
  performancesPerPage: 20,
  morePages: true,
  page: 0,
  infiniteId: +new Date(),
  viewMode: 'full',
};

const getters = {
  /**
    * Returns an array of filters that are selected.
    * @param {object} state - state.filters is filtered for filters that are active
    * @returns {array} an array of filter categories and their active filters is returned.
    */
  selectedFilters: (state) => {
    const activeFilters = [];
    state.filters.forEach((filterCategory) => {
      const filterCategoryName = filterCategory.name;
      const activeFiltersOptions = filterCategory.options.filter(option => option.active);
      activeFiltersOptions.forEach((option) => {
        activeFilters.push({
          filterCategory: filterCategoryName,
          filterCategoryId: filterCategory.id,
          optionsName: option.name,
          optionId: option.id,
        });
      });
    });
    return activeFilters;
  },
  /**
   * Returns the number of filters that are selected.
   * @param {object} state - not used
   * @param {object} getters - getters.selectedFilters.length
   * @returns {integer} integer of the number of selected filters.
   */
  selectedFiltersCount: (state, getters) => getters.selectedFilters.length,

  /**
    * Returns an object of filters that are selected with ids of selected filters.
    * @param {object} state - state.filters is filtered for filters that are active
    * @returns {array} an array of filter categories and their active filters is returned.
    */
  selectedFiltersByType: (state, getters) => {
    const filterCategories = {};
    getters.selectedFilters.forEach((filter) => {
      if (filterCategories[filter.filterCategoryId]) {
        filterCategories[filter.filterCategoryId].push(filter.optionId);
      } else {
        filterCategories[filter.filterCategoryId] = [];
        filterCategories[filter.filterCategoryId].push(filter.optionId);
      }
    });
    return filterCategories;
  },
  /**
  * Filters through performances array and constructs a new array with performances grouped
  * by day with an array of objects for each day and a sub array for each performance on that day.
  * Also only performances that are the same date as the currently selected date or after are added.
  * @param {object} state - uses state.performances and state.date
  * @returns {array} an array of objects for days that then contain each performance for that day.
  */
  performancesByDay(state) {
    let currentDay = moment();
    const performancesByDay = [];
    let performancesOnCurrentDay = [];

    state.performances.forEach((performance) => {
      // check if performance is the same or after currently selected date
      if (moment(performance.datetime).isSameOrAfter(moment(state.date), 'day')) {
        // if the current day array is after the current day and the current array of performances
        // array for the day has a length, push that array with current day string to array
        if (moment(performance.datetime).isAfter(currentDay, 'day')) {
          if (performancesOnCurrentDay.length) {
            performancesByDay.push({
              today: moment(currentDay).isSame(moment(), 'day'),
              date: moment(currentDay).format('ddd Do MMM YYYY'),
              performances: performancesOnCurrentDay,
            });
          }
          // set the current day to the current perforance's date, reset the performances array
          // and push the current performance to the reset array
          currentDay = moment(performance.datetime);
          performancesOnCurrentDay = [];
          performancesOnCurrentDay.push(performance);
        } else {
          performancesOnCurrentDay.push(performance);
        }
      }
    });
    return performancesByDay;
  },
  dateFormatted(state) {
    return moment(state.date).format('DD/MM/YYYY');
  },
};

const mutations = {
  /**
    * Sets the id of the page in the state.
    * @param {object} state
    * @param {integer} payload
    */
  setId(state, payload) {
    state.id = payload;
  },
  /**
    * Sets the filters array in the state.
    * @param {object} state
    * @param {array} payload
    */
  setFilters(state, payload) {
    state.filters = payload;
  },
  /**
    * Toggles an individual filter boolean value and sets all others to false (single select)
    * @param {object} state - state.filters is searched to find the option value to toggle
    * @param {object} payload - has filterCategory and filterId values which are used to determine
    * which category to toggle
    */
  setFilter(state, payload) {
    const filterCategory = state.filters.find(category => category.name === payload.filterCategory);
    const option = filterCategory.options.find(option => option.id === payload.filterId);
    const otherOptions = filterCategory.options.filter(option => option.id !== payload.filterId);
    otherOptions.forEach((option) => { option.active = false; });

    option.active = !option.active;
  },
  /**
  * Set all filters to false
  * @param {object} state - all values in state.filters.category.option.active is set to false
  */
  clearFilters(state) {
    state.filters.forEach((category) => {
      category.options.forEach((option) => {
        option.active = false;
      });
    });
  },
  /**
    * Toggles filterModalActive boolean state
    * @param {object} state
    */
  toggleFilterModal(state) {
    state.filterModalActive = !state.filterModalActive;
  },
  /**
  * Sets the date in the state.
  * @param {object} state
  * @param {string} payload
  */
  setDate(state, payload) {
    if (payload) {
      state.date = payload;
    }
  },
  /**
  * Sets the performances array in the state.
  * @param {object} state
  * @param {array} payload
  */
  setPerformances(state, payload) {
    state.performances = payload;
  },
  /**
  * Sets the view mode in the state.
  * @param {object} state
  * @param {string} payload - either 'full' or 'list'
  */
  setViewMode(state, payload) {
    state.viewMode = payload;
  },
  /**
    * Sets the current page for infinite loading
    * @param {object} state
    */
  setPage(state, payload) {
    state.page = payload;
  },
  /**
    * Sets the morePages value for infinite loading. False if no more pages to load
    * @param {object} state
    */
  setMorePages(state, payload) {
    state.morePages = payload;
  },
  /**
    * Sets a new unique infinite id for infinite loader
    * @param {object} state
    */
  setNewInfiniteId(state) {
    state.infiniteId += 1;
  },
};

const actions = {
  /**
    * commits update to filter state, then clears events and calls api with new settings
    * @param {object} context
    */
  updateFilter({ commit, dispatch }, payload) {
    commit('setFilter', payload);
    dispatch('clearPerformances');
    dispatch('getPerformances');
  },
  /**
  * commits update to filter state (clears them), then clears events and calls api with new settings
  * @param {object} context
  */
  clearFilters({ commit, dispatch }) {
    commit('clearFilters');
    dispatch('clearPerformances');
    dispatch('getPerformances');
  },
  /**
    * commits setFilters mutation with filters array after getting filters from API
    * based on the id of the page
    * @param {object} context
    * @param {object} initiallyActiveFilters - An array of objects that is created based on
    * values from the query string for setting initially selected filters
    * (see EventFilterFilters.vue) on mount
    */
  getFilters(context, initiallyActiveFilters) {
    axios.get(`${process.env.VUE_APP_API_URL}pages/${context.state.id}/`).then(
      (response) => {
        // Set all filters to not active on first load
        const filters = response.data.filters.items;
        filters.forEach((filterCategory) => {
          filterCategory.options.forEach((option) => {
            if (initiallyActiveFilters
              && initiallyActiveFilters[filterCategory.id]
              && initiallyActiveFilters[filterCategory.id].includes(option.id)) {
              option.active = true;
            } else {
              option.active = false;
            }
          });
        });
        context.commit('setFilters', filters);
      }, () => {
        // console.log(error);
      },
    );
  },
  /**
  * commits setFilters mutation with filters array after getting filters from API
  * based on the id of the page
  * @param {object} context
  */
  getPerformances(context) {
    const genreString = context.getters.selectedFiltersByType.genre ? `&genre=${context.getters.selectedFiltersByType.genre.join()}` : '';
    const typeString = context.getters.selectedFiltersByType.event_type_list ? `&performance_event_type=${context.getters.selectedFiltersByType.event_type_list.join()}` : '';
    const programString = context.getters.selectedFiltersByType.program ? `&program=${context.getters.selectedFiltersByType.program.join()}` : '';
    const accessibilityString = context.getters.selectedFiltersByType.accessibility ? `&event_accessibility=${context.getters.selectedFiltersByType.accessibility.join()}` : '';
    const venueString = context.getters.selectedFiltersByType.venue ? `&venue=${context.getters.selectedFiltersByType.venue.join()}` : '';

    const dateString = `&date_from=${moment(context.state.date).format('YYYY-MM-DD')}`;

    const apiString = `${process.env.VUE_APP_API_URL}performances/?limit=${context.state.performancesPerPage}&offset=${(context.state.page) * context.state.performancesPerPage}&fields=*${genreString}${typeString}${programString}${accessibilityString}${venueString}${dateString}`;

    axios.get(apiString).then(
      (response) => {
        if (response.data.items.length) {
          context.commit('setMorePages', true);
          context.commit('setPerformances', [...context.state.performances, ...response.data.items]);
          context.commit('setPage', context.state.page + 1);
        } else {
          context.commit('setMorePages', false);
        }
      }, () => {
        // console.log(error);
      },
    );
  },
  /**
    * Resets event list to an empty array
    * @param {object} context
    */
  clearPerformances({ commit }) {
    commit('setNewInfiniteId');
    commit('setPerformances', []);
    commit('setPage', 0);
    commit('setMorePages', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
