<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#FFF" fill-rule="evenodd" d="M6.14 2.598l2 2.063-3.621 3.734-2-2.063 3.62-3.734zM4.803 8.99l3.911-4.034c.08-.083.12-.18.12-.294a.408.408 0 0 0-.12-.294l-2.29-2.363a.38.38 0 0 0-.286-.117.38.38 0 0 0-.285.117L1.943 6.04a.408.408 0 0 0-.12.293c0 .113.04.211.12.294L4.234 8.99a.38.38 0 0 0 .285.118.38.38 0 0 0 .285-.118zm5.626-4.158l-5.74 5.927a.767.767 0 0 1-.573.242.767.767 0 0 1-.573-.242l-.797-.822c.236-.244.354-.54.354-.888s-.118-.644-.354-.888a1.154 1.154 0 0 0-.86-.365 1.16 1.16 0 0 0-.862.365l-.79-.822A.817.817 0 0 1 0 6.747c0-.233.078-.43.234-.59L5.974.241A.767.767 0 0 1 6.548 0c.226 0 .417.08.573.242l.791.816c-.236.243-.354.54-.354.887 0 .349.118.645.354.888.237.244.524.366.861.366.338 0 .625-.122.86-.366l.798.816a.817.817 0 0 1 .235.591c0 .233-.079.43-.235.59z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-ticket',
};
</script>
