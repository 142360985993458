<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 14 14">
    <!-- eslint-disable-next-line max-len -->
    <g transform="translate(0 8.37)"><path d="M13.77.225h-1.482a.23.23 0 0 0-.23.231v3.201H1.941V.456a.23.23 0 0 0-.23-.231H.23A.231.231 0 0 0 0 .456v4.913c0 .128.104.231.23.231H13.77a.23.23 0 0 0 .23-.231V.456a.23.23 0 0 0-.23-.231" fill="#06038D"/></g><path d="M9.962 4.224h-2v5.734a.25.25 0 0 1-.25.25H6.288a.25.25 0 0 1-.25-.25V4.224h-2a.348.348 0 0 1-.266-.572L6.733.125a.348.348 0 0 1 .534 0l2.961 3.527c.19.226.03.572-.266.572" fill="#06038D"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-upload',
};
</script>
