import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import menu from './modules/menu';
import expandableSections from './modules/expandableSections';
import eventFilter from './modules/eventFilter';
import eventCalendar from './modules/eventCalendar';
import performanceModal from './modules/performanceModal';
import shortlist from './modules/shortlist';
import popupmessageModal from './modules/popupmessageModal';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menu,
    expandableSections,
    eventFilter,
    eventCalendar,
    performanceModal,
    shortlist,
    popupmessageModal,
  },
  plugins: [
    createPersistedState({
      key: 'gpac',
      paths: [
        'menu.firstLoad',
        'shortlist.shortlistEvents',
        'popupmessageModal.newVisitor',
      ],
      storage: {
        getItem: key => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) => Cookies.set(key, value, {
          expires: 2,
          secure: process.env.mode === 'production',
        }),
        removeItem: key => Cookies.remove(key),
      },
    }),
  ],
});
