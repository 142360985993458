<template>
<div
  class="form-item"
  :class="{ 'is-invalid': hasError }"
>
  <label class="form-label">{{ label }}</label>
  <div class="form-select">
    <select
      :name="name"
      :aria-label="label"
      :aria-describedby="labelId"
      @input="$emit('input', $event.target.value)"
    >
      <option
        v-for="(field, id) in selectFields"
        :selected="selected == field.value"
        :key="id"
        :value="field.value"
      >
        {{ field.label }}
      </option>
    </select>
  </div>
  <span
    :id="`${name}-description`"
    class="form-alert"
    aria-live="assertive"
    role="alert"
  >{{ error }}</span>
</div>
</template>

<script>
export default {
  name: 'input-select',
  props: {
    name: {
      type: String,
      required: true,
    },
    selectFields: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelId: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
    },
    hasError: {
      type: Boolean,
    },
    error: {
      type: String,
    },
  },
};
</script>
