<template>
  <li
    class="nav-menu__item"
    :class="{ 'nav-menu__item--highlight': label === 'important', 'nav-menu__item--top': topLevel }"
  >
    <a :href="url" class="nav-menu__link" :tabindex="tabIndex">
      <span v-if="label === 'home'" class="icon icon--home" aria-hidden="true">
        <svg-home></svg-home>
      </span>
      <span v-if="label === 'calendar'" class="icon icon--calendar" aria-hidden="true">
        <svg-calendar></svg-calendar>
      </span>
      <span v-if="label === 'all-events'" class="icon icon--grid" aria-hidden="true">
        <svg-all-events></svg-all-events>
      </span>
      <span class="menu-title">{{ title }}</span>
    </a>
    <button
      v-if="hasChildren"
      class="nav-menu__next"
      :aria-controls="`${slug}-menu`"
      :title="`View ${title} child menu`"
      :tabindex="tabIndex"
      @click="activateChild(navId)">
      <span class="icon icon--arrow" aria-hidden="true"><svg-arrow></svg-arrow></span>
    </button>
  </li>
</template>

<script>
import { mapState } from 'vuex';

import SvgHome from '@/svgs/Home.vue';
import SvgCalendar from '@/svgs/Calendar.vue';
import SvgAllEvents from '@/svgs/AllEvents.vue';
import SvgArrow from '@/svgs/Arrow.vue';

export default {
  name: 'nav-item',
  props: {
    navId: Number,
    parentId: Number,
    url: String,
    title: String,
    slug: String,
    label: {
      type: String,
      default: null,
    },
    hasChildren: Boolean,
    topLevel: Boolean,
  },
  components: {
    SvgHome,
    SvgCalendar,
    SvgAllEvents,
    SvgArrow,
  },
  computed: {
    ...mapState({
      activeChild: state => state.menu.activeChild,
    }),
    tabIndex() {
      if (this.activeChild[this.activeChild.length - 1] === this.parentId) { // If active menu
        return '0';
      }
      return '-1';
    },
  },
  methods: {
    activateChild(id) {
      this.$store.commit('menu/activateChild', id);
    },
  },
};
</script>
