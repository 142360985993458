/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios';

const state = {
  id: 0,
  filters: [],
  events: [],
  eventsPerPage: 6,
  eventPage: 0,
  morePages: true,
  infiniteId: +new Date(),
  searchText: '',
  selectedSortOrder: {
    id: 0,
    label: 'By date',
  },
  filterModalActive: false,
  showPastEvents: false,
};

const getters = {
  /**
  * Returns the number of events after filtering.
  * @param {object} state - get length of events array
  * @returns {integer} integer of the length of events.
  */
  eventsCount: state => state.events.length,
  /**
  * Returns an array of filters that are selected.
  * @param {object} state - state.filters is filtered for filters that are active
  * @returns {array} an array of filter categories and their active filters is returned.
  */
  selectedFilters: (state) => {
    const activeFilters = [];
    state.filters.forEach((filterCategory) => {
      const filterCategoryName = filterCategory.name;
      const activeFiltersOptions = filterCategory.options.filter(option => option.active);
      activeFiltersOptions.forEach((option) => {
        activeFilters.push({
          filterCategory: filterCategoryName,
          filterCategoryId: filterCategory.id,
          optionsName: option.name,
          optionId: option.id,
        });
      });
    });
    return activeFilters;
  },
  /**
  * Returns the number of filters that are selected.
  * @param {object} state - not used
  * @param {object} getters - getters.selectedFilters.length
  * @returns {integer} integer of the number of selected filters.
  */
  selectedFiltersCount: (state, getters) => getters.selectedFilters.length,
  /**
    * Returns an object of filters that are selected with ids of selected filters.
    * @param {object} state - state.filters is filtered for filters that are active
    * @returns {array} an array of filter categories and their active filters is returned.
    */
  selectedFiltersByType: (state, getters) => {
    const filterCategories = {};
    getters.selectedFilters.forEach((filter) => {
      if (filterCategories[filter.filterCategoryId]) {
        filterCategories[filter.filterCategoryId].push(filter.optionId);
      } else {
        filterCategories[filter.filterCategoryId] = [];
        filterCategories[filter.filterCategoryId].push(filter.optionId);
      }
    });
    return filterCategories;
  },
};

const mutations = {
  /**
  * Sets the id of the page in the state.
  * @param {object} state
  * @param {integer} payload
  */
  setId(state, payload) {
    state.id = payload;
  },
  /**
  * Sets the filters array in the state.
  * @param {object} state
  * @param {array} payload
  */
  setFilters(state, payload) {
    state.filters = payload;
  },
  /**
  * Sets the events array in the state.
  * @param {object} state
  * @param {array} payload
  */
  setEvents(state, payload) {
    state.events = payload;
  },
  /**
  * Sets the search text string in the state.
  * @param {object} state
  * @param {string} payload
  */
  setSearchText(state, payload) {
    state.searchText = payload;
  },
  /**
  * Sets the sort order object in state.
  * @param {object} state
  * @param {object} payload - has id and label values
  */
  setSelectedSortOrder(state, payload) {
    state.selectedSortOrder = payload;
  },
  /**
  * Toggles an individual filter boolean value and sets all others to false (single select)
  * @param {object} state - state.filters is searched to find the option value to toggle
  * @param {object} payload - has filterCategory and filterId values which are used to determine
  * which category to toggle
  */
  setFilter(state, payload) {
    const filterCategory = state.filters.find(category => category.name === payload.filterCategory);
    const option = filterCategory.options.find(option => option.id === payload.filterId);
    const otherOptions = filterCategory.options.filter(option => option.id !== payload.filterId);
    otherOptions.forEach((option) => { option.active = false; });

    option.active = !option.active;
  },
  /**
  * Set all filters to false
  * @param {object} state - all values in state.filters.category.option.active is set to false
  */
  clearFilters(state) {
    state.filters.forEach((category) => {
      category.options.forEach((option) => {
        option.active = false;
      });
    });
  },
  /**
  * Toggles filterModalActive boolean state
  * @param {object} state
  */
  toggleFilterModal(state) {
    state.filterModalActive = !state.filterModalActive;
  },
  /**
  * Toggles showPastEvents boolean in state
  * @param {object} state
  */
  toggleShowPastEventsState(state) {
    state.showPastEvents = !state.showPastEvents;
  },
  /**
    * Sets the current page for infinite loading
    * @param {object} state
    */
  setEventPage(state, payload) {
    state.eventPage = payload;
  },
  /**
    * Sets the morePages value for infinite loading. False if no more pages to load
    * @param {object} state
    */
  setMorePages(state, payload) {
    state.morePages = payload;
  },
  /**
    * Sets a new unique infinite id for infinite loader
    * @param {object} state
    */
  setNewInfiniteId(state) {
    state.infiniteId += 1;
  },
};

const actions = {
  /**
  * commits toggleShowPastEventsState mutation
  * @param {object} context
  */
  toggleShowPastEvents({ commit, dispatch }) {
    commit('toggleShowPastEventsState');
    dispatch('clearEvents');
    dispatch('getEvents');
    dispatch('setPageUrl');
  },
  /**
  * commits setSearchText mutation and debounces dispatch of getEvents action
  * @param {object} context
  * @param {string} payload
  */
  updateTextSearchResult({ commit, dispatch }, payload) {
    commit('setSearchText', payload);
    // debounce dispatch to api
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      dispatch('clearEvents');
      dispatch('getEvents');
    }, 400);
  },
  /**
  * commits update to filter state, then clears events, calls api with new settings,
  * then changes the url
  * @param {object} context
  */
  updateFilter({ commit, dispatch }, payload) {
    commit('setFilter', payload);
    dispatch('clearEvents');
    dispatch('getEvents');
    dispatch('setPageUrl');
  },
  /**
  * commits update to filter state (clears them), then clears events, calls api with new settings
  * then changes the url
  * @param {object} context
  */
  clearFilters({ commit, dispatch }) {
    commit('clearFilters');
    dispatch('clearEvents');
    dispatch('getEvents');
    dispatch('setPageUrl');
  },
  /**
  * commits update to order state, then clears events, calls api with new settings,
  * then changes the url
  * @param {object} context
  */
  setSelectedSortOrder({ commit, dispatch }, payload) {
    commit('setSelectedSortOrder', payload);
    dispatch('clearEvents');
    dispatch('getEvents');
    dispatch('setPageUrl');
  },
  /**
  * commits setFilters mutation with filters array after getting filters from API
  * based on the id of the page
  * @param {object} context
  * @param {object} initialFilters - An array of objects that is created based on
  * values from the query string for setting initially selected filters (see EventFilterFilters.vue)
  * on mount
  */
  getFilters(context, initialFilters) {
    axios.get(`${process.env.VUE_APP_API_URL}pages/${context.state.id}/`).then(
      (response) => {
        // Set all filters to not active on first load
        const filters = response.data.filters.items;
        filters.forEach((filterCategory) => {
          filterCategory.options.forEach((option) => {
            if (!initialFilters) {
              option.active = false;
            } else {
              option.active = parseInt(initialFilters[filterCategory.id], 10) === option.id;
            }
          });
        });
        context.commit('setFilters', filters);
      }, (error) => {
        console.log(error);
      },
    );
  },
  /**
  * Builds query string for event API that uses all
  * filter, order, search and past/future events settings from vuex
  * @param {object} context
  */
  getEvents(context) {
    let searchString = '';
    if (context.state.searchText) {
      searchString = `&search=${context.state.searchText}`;
    }
    const pastEventsString = context.state.showPastEvents ? '&past_events' : '';
    const genreString = context.getters.selectedFiltersByType.genre ? `&genre=${context.getters.selectedFiltersByType.genre.join()}` : '';
    const typeString = context.getters.selectedFiltersByType.event_type_list ? `&event_type=${context.getters.selectedFiltersByType.event_type_list.join()}` : '';
    const programString = context.getters.selectedFiltersByType.program ? `&program=${context.getters.selectedFiltersByType.program.join()}` : '';
    const accessibilityString = context.getters.selectedFiltersByType.accessibility ? `&accessibility=${context.getters.selectedFiltersByType.accessibility.join()}` : '';
    const venueString = context.getters.selectedFiltersByType.venue ? `&event_venue=${context.getters.selectedFiltersByType.venue.join()}` : '';

    let orderString = '';
    if (context.state.selectedSortOrder.id === 1) {
      orderString = '&order=title';
    } else if (context.state.selectedSortOrder.id === 2) {
      orderString = '&order=-title';
    }
    const offset = (context.state.eventPage) * context.state.eventsPerPage;
    const apiString = `${process.env.VUE_APP_API_URL}event_pages/?limit=${context.state.eventsPerPage}&offset=${offset}&fields=*${searchString}${pastEventsString}${genreString}${typeString}${programString}${accessibilityString}${venueString}${orderString}`;
    axios.get(apiString).then(
      (response) => {
        if (response.data.items.length) {
          context.commit('setMorePages', true);
          context.commit('setEvents', [...context.state.events, ...response.data.items]);
          context.commit('setEventPage', context.state.eventPage + 1);
        } else {
          context.commit('setMorePages', false);
        }
      }, (error) => {
        console.log(error);
      },
    );
  },


  /**
  * Builds query string and pushes state/url to history and address bar
  * filter, order and past/future events settings from vuex
  * @param {object} context
  */
  setPageUrl(context) {
    const params = [];
    context.getters.selectedFilters.forEach((filter) => {
      params.push(`${filter.filterCategoryId}=${filter.optionId}`);
    });
    if (context.state.showPastEvents) {
      params.push('past_events');
    }
    if (context.state.selectedSortOrder.id !== 0) {
      const order = context.state.selectedSortOrder.id === 1 ? 'title' : '-title';
      params.push(`order=${order}`);
    }
    if (params.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, null, `?${params.join('&')}`);
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, null, location.href.split('?')[0]);
    }
  },


  /**
  * Resets event list to an empty array
  * @param {object} context
  */
  clearEvents({ commit }) {
    commit('setNewInfiniteId');
    commit('setEvents', []);
    commit('setEventPage', 0);
    commit('setMorePages', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
