<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 22">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#3A37CC" fill-rule="evenodd" d="M16.696 14.592l1.04 6.1-5.447-2.88a1.64 1.64 0 0 0-.763-.19V1.472l2.724 5.55c.238.486.7.823 1.234.902l6.09.89-4.407 4.32a1.654 1.654 0 0 0-.471 1.459zm6.1-5.573a.644.644 0 0 0-.355-1.096l-6.814-.996a.64.64 0 0 1-.48-.351L12.098.366a.631.631 0 0 0-.573-.358.631.631 0 0 0-.573.358l-2.999 6.11a.848.848 0 0 1-.639.467l-5.031.736-1.674.244A.644.644 0 0 0 .256 9.02l.304.298 4.548 4.459c.2.196.291.478.244.755l-1.074 6.296-.071.42a.643.643 0 0 0 .63.753c.098 0 .2-.024.297-.075l.375-.199 1.667-.88 4.053-2.144a.636.636 0 0 1 .594 0l6.095 3.223c.098.051.2.075.297.075a.643.643 0 0 0 .63-.753l-1.164-6.825a.645.645 0 0 1 .184-.569l4.93-4.834z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-star-half',
};
</script>
