<template>
  <article class="accordion" v-if="display">
    <button
      class="accordion-toggle"
      :title="`toggle accordion content for ${question}`"
      :aria-controls="`accordion-${question}`"
      :aria-expanded="active ? 'true' : 'false'"
      @click="active = !active"
      :class="{ 'is-active': active }"
    >
      <span class="accordion-title">{{ question }}</span>
      <span class="accordion-arrow">
        <span aria-hidden="true" class="icon icon--arrow-wide">
          <svg-arrow-wide></svg-arrow-wide>
        </span>
      </span>
    </button>
    <div
      :id="`accordion-${question}`"
      class="accordion-content"
    >
      <div class="rich-text" v-html="answer"></div>
      <a class="accordion-button button is-outlined is-regular" :href="moreInfo" v-if="moreInfo">
        <span>More Info</span>
      </a>
    </div>
  </article>
</template>

<script>
import axios from 'axios';
import SvgArrowWide from '@/svgs/ArrowWide.vue';

export default {
  name: 'faq',
  props: ['faqId', 'keyword'],
  data() {
    return {
      question: '',
      answer: '',
      moreInfo: null,
      active: false,
    };
  },
  components: { SvgArrowWide },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}faqs/${this.faqId}/`)
      .then((response) => {
        this.question = response.data.question;
        this.answer = response.data.answer;
        this.moreInfo = response.data.more_info;
      });
  },
  computed: {
    display() {
      if (!this.keyword) {
        return true;
      }
      const regex = new RegExp(this.keyword, 'i');
      if (this.question.match(regex)) {
        return true;
      }
      if (this.answer.match(regex)) {
        return true;
      }
      return false;
    },
  },
};
</script>
