<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#8785FF" fill-rule="evenodd" d="M20 0c-2.764 0-5.36.522-7.785 1.567C9.79 2.61 7.676 4.035 5.874 5.839c-1.802 1.804-3.232 3.92-4.289 6.348C.528 14.615 0 17.213 0 19.98c0 2.767.528 5.364 1.585 7.792s2.487 4.544 4.289 6.348c1.802 1.804 3.916 3.235 6.341 4.293C14.641 39.471 17.235 40 20 40c2.764 0 5.36-.529 7.785-1.587 2.425-1.058 4.539-2.489 6.341-4.293 1.802-1.804 3.232-3.92 4.289-6.348C39.472 25.344 40 22.747 40 19.98c0-2.767-.528-5.365-1.585-7.793s-2.487-4.544-4.289-6.348c-1.802-1.804-3.916-3.228-6.341-4.272C25.359.522 22.765 0 20 0zm8.13 16.358v.529c0 1.302-.244 2.618-.732 3.947a11.224 11.224 0 0 1-2.154 3.601c-.949 1.072-2.114 1.947-3.496 2.625-1.382.678-2.981 1.017-4.797 1.017-1.11 0-2.175-.156-3.19-.468a12.67 12.67 0 0 1-2.867-1.281c.163 0 .326.006.488.02a7.662 7.662 0 0 0 3.11-.427 8.427 8.427 0 0 0 2.256-1.221 3.846 3.846 0 0 1-2.297-.814 4.126 4.126 0 0 1-1.402-1.953c.135.027.264.047.386.06.122.014.237.021.345.021.19 0 .373-.013.55-.04l.507-.082c-.894-.19-1.646-.644-2.256-1.363a3.752 3.752 0 0 1-.914-2.503v-.04c.27.135.555.244.853.325.298.082.61.136.935.163a4.073 4.073 0 0 1-1.26-1.404 3.822 3.822 0 0 1-.488-1.892c0-.353.048-.699.143-1.038.094-.339.223-.657.386-.956a11.608 11.608 0 0 0 3.557 2.91c1.395.746 2.92 1.16 4.573 1.24a2.08 2.08 0 0 1-.102-.467 4.731 4.731 0 0 1-.02-.428c0-1.085.386-2.014 1.158-2.787.773-.773 1.701-1.16 2.785-1.16.57 0 1.098.109 1.585.326.488.217.922.515 1.301.895a7.16 7.16 0 0 0 1.28-.366c.42-.163.82-.353 1.2-.57a3.515 3.515 0 0 1-.65 1.241 4.79 4.79 0 0 1-1.057.957 8.6 8.6 0 0 0 1.138-.245c.38-.108.745-.244 1.097-.407a5.79 5.79 0 0 1-.874 1.12 8.509 8.509 0 0 1-1.077.915z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-social-twitter',
};
</script>
