<template>
  <button class="button" :class="className" @click="loadModal" v-if="performances">
    <span class="icon icon--ticket"><svg-ticket></svg-ticket></span>
    <span>{{ text }}</span>
  </button>
</template>

<script>
import axios from 'axios';
import SvgTicket from '@/svgs/Ticket.vue';

export default {
  name: 'buy-tickets',
  props: {
    className: String,
    displayTitle: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      default: 'Buy tickets',
    },
  },
  components: { SvgTicket },
  data() {
    return {
      performances: null,
      title: null,
      url: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`${process.env.VUE_APP_API_URL}event_pages/${this.eventId}/`)
        .then((response) => {
          this.performances = response.data.performances.map(item => ({
            performanceId: item.ticketing_code,
            formatted_date: item.formatted_datetime,
            purchaseLink: item.purchase_link,
            waitlistLink: item.waitlist_link,
            isPast: item.is_past,
          }));
          this.title = response.data.title;
          this.url = response.data.url;
        })
        .catch((error) => {
          console.log(`Issue with ${this.eventId}: ${error}`);
        });
    },
    loadModal() {
      this.$store.commit('performanceModal/setModalPerformances', this.performances);
      if (this.displayTitle) {
        this.$store.commit('performanceModal/setEventTitle', this.title);
        this.$store.commit('performanceModal/setEventLink', this.url);
      }
    },
  },
};
</script>
