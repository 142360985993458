const state = {
  newVisitor: true,
};

const mutations = {
  /**
   * Sets to false if visited.
   */
  // eslint-disable-next-line no-shadow
  setNewVisitor(state) {
    // eslint-disable-next-line no-param-reassign
    state.newVisitor = false;
  },

};

export default {
  namespaced: true,
  state,
  mutations,
};
