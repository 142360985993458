<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 2">
    <path fill="#06038D" d="M0 0h8v2H0z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-minus',
};
</script>
