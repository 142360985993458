<template>
  <li class="sidebar-menu__item">
    <div class="sidebar-menu__wrap">
      <a
        :href="url"
        class="sidebar-menu__link"
        :class="{ 'is-active': active }"
        :aria-current="ariaCurrent"
      >
        <button
          v-if="parent"
          class="sidebar-menu__toggle"
          :class="{ 'is-active': expanded }"
          :title="titleText"
          :aria-controls="ariaControls"
          :aria-expanded="ariaExpanded"
          @click.prevent="toggleMenu"
        ></button>
        <span>{{ title }}</span>
      </a>
      <template v-if="parent">
        <ul class="sidebar-submenu" :class="{ 'is-active': expanded }" :id="ariaControls">
          <slot></slot>
        </ul>
      </template>
    </div>
  </li>
</template>

<script>
export default {
  name: 'sidebar-block',
  props: {
    title: String,
    slug: String,
    url: String,
    active: Boolean,
    parent: Boolean,
  },
  data() {
    return {
      expanded: this.active,
    };
  },
  computed: {
    titleText() {
      return `Expand submenu for ${this.title}`;
    },
    ariaCurrent() {
      return this.active ? 'page' : '';
    },
    ariaControls() {
      return `sidebar-${this.slug}`;
    },
    ariaExpanded() {
      return this.expanded.toString();
    },
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
