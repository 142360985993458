<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
    <path d="M0 3h8v2H0z" fill="#06038D"/><path d="M3 0h2v8H3z" fill="#06038D"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-plus',
};
</script>
