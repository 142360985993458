<template>
  <div class="pill">
    <span class="pill__text">{{ filter.optionsName }}</span>
    <div class="icon icon--close-small" @click="remove">
      <svg-close />
    </div>
  </div>
</template>

<script>
import SvgClose from '@/svgs/Close.vue';

export default {
  props: {
    filter: Object,
    performancesPage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SvgClose,
  },
  methods: {
    remove() {
      // if id is 0, remove all otherwise only remove filter with id and filter category
      if (this.filter.optionId === 0) {
        // remove all categories
        if (this.performancesPage) {
          this.$store.dispatch('eventCalendar/clearFilters');
        } else {
          this.$store.dispatch('eventFilter/clearFilters');
        }
      // remove filter with id and category name
      } else if (this.performancesPage) {
        this.$store.dispatch('eventCalendar/updateFilter', {
          filterCategory: this.filter.filterCategory,
          filterId: this.filter.optionId,
        });
      } else {
        this.$store.dispatch('eventFilter/updateFilter', {
          filterCategory: this.filter.filterCategory,
          filterId: this.filter.optionId,
        });
      }
    },
  },
};
</script>
