const state = {
  performances: [],
  eventTitle: '',
  eventLink: '',
};

const mutations = {
  /**
   * Add performances to state
   *
   * @param {Object} state
   * @param {Array} payload
   */
  /* eslint-disable-next-line no-shadow */
  setModalPerformances(state, payload) {
    /* eslint-disable-next-line no-param-reassign */
    state.performances = payload;
  },
  /**
   * remove performances, event title and link from state
   * @param {Object} state
   */
  /* eslint-disable-next-line no-shadow */
  clearPerformances(state) {
    /* eslint-disable-next-line no-param-reassign */
    state.performances = [];
    /* eslint-disable-next-line no-param-reassign */
    state.eventTitle = '';
    /* eslint-disable-next-line no-param-reassign */
    state.eventLink = '';
  },
  /**
   * Set event title
   *
   * @param {Object} state
   * @param {String} payload
   */
  /* eslint-disable-next-line no-shadow */
  setEventTitle(state, payload) {
    /* eslint-disable-next-line no-param-reassign */
    state.eventTitle = payload;
  },
  /**
   * Set event link
   *
   * @param {Object} state
   * @param {String} payload
   */
  /* eslint-disable-next-line no-shadow */
  setEventLink(state, payload) {
    /* eslint-disable-next-line no-param-reassign */
    state.eventLink = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
