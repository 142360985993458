<template>
  <div class="column is-7-desktop is-8-widescreen">
    <div v-for="(day, index) in performancesByDay" :key="index" class="event-calendar__day">
      <div class="event-calendar__day-title has-text-blue has-background-blue-tint-lighter">
        <p v-if="day.today" class="is-small is-family-monospace is-uppercase">Today</p>
        <h2 class="has-text-weight-bold">{{ day.date }}</h2>
      </div>

      <div class="columns is-multiline">
        <div
          v-for="(performance, index) in day.performances"
          :key="`${day.date}-${index}-${performance.id}`"
          :class="{'is-half-tablet' : viewMode === 'full'}"
          class="column is-full-tablet"
        >
          <event-card
            v-if="viewMode === 'full'"
            :event-id="performance.event.id"
            :on-sale="performance.event.on_sale"
            :title="performance.event.title"
            :page-url="performance.event.url"
            :image="performance.event.thumbnail"
            :genre="performance.genre"
            :dates="performance.event.date_range"
            :label="performance.event.label"
            :custom-label="performance.event.customLabel"
            :ticket-button-text="performance.event.ticket_button_text"
          />
          <performance-card-list v-else :performance="performance" />
        </div>
      </div>
    </div>
    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
    >
      <div slot="spinner">
        <load-more-animation class="performance-list-loadmore-animation" />
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
/* eslint-disable-next-line import/no-unresolved */
import InfiniteLoading from 'vue-infinite-loading';

import EventCard from '@/components/events/EventCard.vue';
import PerformanceCardList from '@/components/events/EventCalendar/PerformanceCardList.vue';
import LoadMoreAnimation from '@/components/standard/LoadMoreAnimation.vue';

export default {
  components: {
    EventCard,
    PerformanceCardList,
    InfiniteLoading,
    LoadMoreAnimation,
  },
  mounted() {
    this.$store.dispatch('eventCalendar/getPerformances');
  },
  methods: {
    /**
    * Handler for infinite loading component.
    * If vuex store is true it will get next performances and set state to loaded
    * otherwise if no more posts, sets state to complete
    */
    infiniteHandler($state) {
      if (this.morePages) {
        setTimeout(() => this.$store.dispatch('eventCalendar/getPerformances').then(() => {
          $state.loaded();
        }), 500);
      } else {
        $state.complete();
      }
    },
  },
  computed: {
    ...mapState({
      viewMode: state => state.eventCalendar.viewMode,
      morePages: state => state.eventCalendar.morePages,
      infiniteId: state => state.eventCalendar.infiniteId,
    }),
    ...mapGetters({
      performancesByDay: 'eventCalendar/performancesByDay',
    }),
  },
};
</script>
