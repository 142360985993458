<template>
  <div :class="statusClass" class="event-performance">
    <template v-if="waitListActive && waitlistLink">
      <a :href="waitlistLink" target="_blank">
        <span class="event-performance__date">{{ date }}</span>
        <span class="event-performance__time">{{ time }}</span><br />
        <span v-if="status" class="event-performance__status">(Wait List)</span>
      </a>
    </template>
    <template v-else-if="waitListActive">
      <span class="event-performance__date">{{ date }}</span>
      <span class="event-performance__time">{{ time }}</span><br />
      <span v-if="status" class="event-performance__status">(Wait List)</span>
    </template>
    <template v-else-if="purchaseLink && status !== 'Sold Out'">
      <a :href="purchaseLink" target="_blank" class="button is-outlined is-fullwidth">
        <span class="event-performance__date">{{ date }}</span>
        <span class="event-performance__time">{{ time }}</span>
        <span v-if="status" class="event-performance__status--link">{{ status }}</span>
      </a>
    </template>
    <template v-else-if="purchaseLink && status === 'Sold Out'">
      <button class="button is-outlined is-fullwidth" disabled>
        <span class="event-performance__date">{{ date }}</span>
        <span class="event-performance__time">{{ time }}</span>
        <span v-if="status" class="event-performance__status--link">{{ status }}</span>
      </button>
    </template>
    <template v-else>
      <span class="event-performance__date">{{ date }}</span>
      <span class="event-performance__time">{{ time }}</span>
      <span v-if="status" class="event-performance__status">{{ status }}</span>
    </template>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'event-performance',
  props: {
    perfId: Number,
    perfTime: Object,
    purchaseLink: String,
    waitlistLink: String,
    isPast: Boolean,
  },
  data() {
    return {
      status: null,
      waitList: false,
    };
  },
  mounted() {
    if (this.perfId) {
      this.getPerformanceStatus();
    }
  },
  computed: {
    /**
     * Add class if past event or performance status
     */
    statusClass() {
      if (this.isPast) {
        return 'event-performance--past-event';
      }
      if (this.waitListActive) {
        return '';
      }
      if (this.status === 'Sold Out') {
        return 'event-performance--sold-out';
      }
      if (this.status === 'Selling fast!') {
        return 'event-performance--selling-fast';
      }
      return '';
    },
    date() {
      return this.perfTime.date;
    },
    time() {
      return this.perfTime.time;
    },
    waitListActive() {
      return this.waitList && this.status === 'Sold Out';
    },
  },
  methods: {
    /**
     * Update performance status via API.
     */
    getPerformanceStatus() {
      axios.get(`https://tickets.geelongartscentre.org.au/PerformanceAvailabilityAPI/api/Performance/${this.perfId}`)
        .then((response) => {
          if (response.data[0].sellingStatus !== 'Good Availability') {
            this.status = response.data[0].sellingStatus;
          }
          this.waitList = response.data[0].waitListEnabled;
        })
        .catch(() => {
          console.log(`Issue with ${this.perfId}`);
        });
    },
  },
};
</script>
