<template>
  <article class="card-event card-event--list">
    <div class="columns is-mobile">
      <div class="column is-4-mobile is-2-tablet is-3-desktop card-event-list__img-column">
        <a :href="performance.event.url" class="card-event__img-link">
          <img :src="thumbnailUrl" alt="" class="is-block"/>

          <shortlist-heart
            class="card-event__heart"
            :title="buttonTitle"
            :event-id="performance.event.id"
            :event-title="performance.event.title"
          ></shortlist-heart>
        </a>
      </div>
      <div class="column is-10-tablet is-9-desktop">
        <event-label
          v-if="displayedGenre"
          class="card-event__genre"
          :labelText="displayedGenre"
          labelType="genre"
        />
        <event-label v-if="labelText" class="card-event__label" :labelText="labelText"/>

        <div class="columns is-gapless">
          <div class="column is-9-tablet is-8-desktop">
            <div class="card-event__details">
              <h6 class="card-event__title">{{ performance.event.title }}</h6>
              <p class="card-event__date">{{ performance.event.date_range }}</p>
            </div>
          </div>
          <div class="column is-3-tablet is-4-desktop">
            <div class="card-event__ctas">
              <a
                :href="performance.event.url"
                class="button is-small is-outlined"
                title="navigate to event page"
              >
                <span>More info</span>
              </a>
              <div class="card-event__modal">
                <buy-tickets
                  v-if="performance.event.on_sale"
                  :event-id="performance.event.id"
                  :display-title="true"
                  :text="performance.event.ticket_button_text"
                  class-name="is-small"
                ></buy-tickets>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import BuyTickets from '@/components/events/BuyTickets.vue';
import EventLabel from '@/components/events/EventLabel.vue';
import ShortlistHeart from '@/components/events/ShortlistHeart.vue';

export default {
  components: {
    BuyTickets,
    EventLabel,
    ShortlistHeart,
  },
  props: {
    performance: {
      type: Object,
      required: true,
    },
  },
  computed: {
    buttonTitle() {
      return `add ${this.performance.event.title} to events shortlist`;
    },
    displayedGenre() {
      if (this.performance.event.genre.length > 0) {
        return this.performance.event.genre[0].name;
      }
      return '';
    },
    labelText() {
      if (this.performance.event.customLabel) return this.event.customLabel;
      if (this.performance.event.label) return this.event.label;
      return null;
    },
    thumbnailUrl() {
      if (this.performance.event.thumbnail) {
        return this.performance.event.thumbnail.url;
      }
      return null;
    },
  },
};
</script>
