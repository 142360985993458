<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#06038D" fill-rule="evenodd" d="M15 13.302c0 .833-.26 1.491-.778 1.974-.518.483-1.207.724-2.066.724H2.844c-.859 0-1.548-.241-2.066-.724C.259 14.793 0 14.136 0 13.302c0-.368.012-.727.037-1.078.025-.35.075-.73.15-1.135.074-.407.168-.783.282-1.13.113-.348.266-.686.458-1.016a3.7 3.7 0 0 1 .66-.844c.249-.233.553-.418.911-.557a3.264 3.264 0 0 1 1.188-.209c.064 0 .213.075.448.224.234.15.498.316.793.5.295.184.678.351 1.15.5.473.15.947.224 1.423.224.476 0 .95-.074 1.422-.224.473-.149.856-.316 1.15-.5.296-.184.56-.35.794-.5.235-.149.384-.224.448-.224.433 0 .83.07 1.188.209.358.139.662.324.91.557.25.233.47.514.661.844.192.33.345.668.458 1.015.114.348.208.724.283 1.13.074.407.124.785.149 1.136.025.35.037.71.037 1.078zM11.59 4c0 1.104-.399 2.047-1.198 2.828C9.593 7.61 8.63 8 7.5 8s-2.093-.39-2.892-1.172C3.808 6.047 3.409 5.104 3.409 4c0-1.104.4-2.047 1.199-2.828C5.407.39 6.37 0 7.5 0s2.093.39 2.892 1.172c.8.781 1.199 1.724 1.199 2.828z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-user',
};
</script>
