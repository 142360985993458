var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-filter__filters"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half-tablet is-8-widescreen"},[(_vm.searchText)?_c('p',{staticClass:"is-small has-text-blue event-filter__filters__search-text"},[_vm._v("\n          "+_vm._s(_vm.eventCount)+" result"+_vm._s(_vm.plural)+" for '"+_vm._s(_vm.searchText)+"'\n        ")]):_vm._e(),(_vm.selectedFiltersCount)?_c('event-filter-pill',{attrs:{"filter":{
            optionId: 0,
            optionsName: 'Clear all filters'
          }}}):_vm._e(),_vm._l((_vm.selectedFilters),function(filter){return _c('event-filter-pill',{key:filter.id,attrs:{"filter":filter}})})],2),_c('div',{staticClass:"column is-3-tablet is-2-widescreen"},[_c('dropdown-button',{attrs:{"label":"Filter results","modal":true,"activeFilters":_vm.selectedFiltersCount},on:{"click":_vm.toggleFilterModal}}),(_vm.filterModalActive)?_c('dropdown-modal',{attrs:{"filters":_vm.filters},on:{"toggleModal":_vm.toggleFilterModal}}):_vm._e()],1),_c('div',{staticClass:"column is-3-tablet is-2-widescreen"},[_c('dropdown-button',{attrs:{"label":_vm.selectedSortOrder.label}},[_c('dropdown-items',{attrs:{"items":[
            {
              id: 0,
              label: 'By date',
            },
            {
              id: 1,
              label: 'A - Z',
            },
            {
              id: 2,
              label: 'Z - A',
            } ]}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }