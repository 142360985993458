<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 22">
    <!-- eslint-disable-next-line max-len -->
    <path fill="#3A37CC" fill-rule="evenodd" d="M15.132 6.57a.64.64 0 0 0 .483.352l6.835.996a.644.644 0 0 1 .356 1.097l-4.947 4.835a.644.644 0 0 0-.184.57l1.168 6.827a.641.641 0 0 1-.93.678L11.797 18.7a.64.64 0 0 0-.596 0l-6.114 3.224a.641.641 0 0 1-.93-.678l1.167-6.828a.644.644 0 0 0-.184-.569L.194 9.015A.644.644 0 0 1 .55 7.918l6.835-.996a.641.641 0 0 0 .483-.352L10.925.358a.64.64 0 0 1 1.15 0l3.057 6.212z"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-star-full',
};
</script>
