<template>
  <div></div>
</template>


<script>
export default {
  name: 'app',
};
</script>

<style lang="sass" src="./sass/app.sass"></style>
