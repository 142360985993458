<template>
  <div>
    <section class="faq__categories">
      <div class="container">
        <div class="columns">
          <div class="column is-6 is-4-desktop is-offset-2-desktop">
            <label class="label">Search questions</label>
            <div class="control">
              <input class="input" v-model="keyword" />
              <span class="icon icon--search"><search-svg></search-svg></span>
            </div>
          </div>
          <div class="column is-6 is-4-desktop">
            <label class="label">Show questions relating to:</label>
            <div class="select is-fullwidth">
              <select v-model="filter">
                <option value="">All categories</option>
                <option :value="category.id" v-for="category in categories" :key="category.id">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="faq__contents">
      <div class="container">
        <div class="columns">
          <div class="column is-8-desktop is-offset-2-desktop">
            <div class="faq-category" v-for="category in fiteredCategories" :key="category.id">
              <h4 class="title is-subheading" v-if="showCategories">{{ category.name }}</h4>
              <faq :faqId="faq" v-for="faq in category.faqs" :key="faq" :keyword="keyword"></faq>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Faq from './Faq.vue';
import SearchSvg from '@/svgs/Search.vue';

export default {
  name: 'faq-index',
  props: {
    pageId: Number,
  },
  components: { Faq, SearchSvg },
  data() {
    return {
      categories: [],
      filter: '',
      keyword: '',
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}pages/${this.pageId}/`)
      .then((response) => {
        this.categories = response.data.faq_categories;
      });
  },
  computed: {
    fiteredCategories() {
      return this.categories.filter((category) => {
        if (this.filter) {
          return category.id === this.filter;
        }
        return true;
      });
    },
    showCategories() {
      if (this.filter) {
        return false;
      }
      if (this.keyword !== '') {
        return false;
      }
      return true;
    },
  },
};
</script>
