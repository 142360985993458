<template>
  <article class="card-news">
    <a
      v-if="image"
      :href="pageurl"
      class="card-news__img"
      :style="imgBackground"
      aria-hidden="true"
    ></a>
    <div class="card-news__text" :class="{ 'has-no-image': !image }">
      <h5 class="card-news__title">{{ title }}</h5>
      <div class="card-news__summary" v-html="summary"></div>
    </div>
    <div class="card-news__meta">
      <p class="card-news__date">{{ publishedDate }}</p>
      <a :href="pageurl" class="card-news__cta" :title="linkTitle">
        <span>Read more</span>
        <span class="icon icon--arrow" aria-hidden="true"><arrow-svg></arrow-svg></span>
      </a>
    </div>
  </article>
</template>

<script>
import axios from 'axios';
import ArrowSvg from '@/svgs/Arrow.vue';

export default {
  name: 'news-card',
  components: { ArrowSvg },
  props: {
    pageId: Number,
  },
  data() {
    return {
      title: '',
      pageurl: '',
      image: '',
      publishedDate: '',
      summary: '',
    };
  },
  computed: {
    imgBackground() {
      if (this.image) {
        return `background-image: url(${this.image});`;
      }
      return '';
    },
    linkTitle() {
      return `navigate to ${this.title} page`;
    },
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}pages/${this.pageId}/`)
      .then((response) => {
        this.title = response.data.title;
        this.pageurl = response.data.url;
        if (response.data.resized_thumbnail) {
          this.image = response.data.resized_thumbnail.url;
        }
        this.publishedDate = response.data.published_date;
        this.summary = response.data.truc_summary;
      });
  },
};
</script>
