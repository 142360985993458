<template>
  <div class="tabs__container">
    <div class="tabs">
      <ul class="tabs__list" role="tablist" aria-label="Event information">
        <li
          v-for="(tab, idx) in tabs"
          class="tabs__tab"
          role="tab"
          :aria-selected="tab.isActive"
          :aria-controls="`${tab.label}-tab`"
          :id="tab.label"
          :key="idx"
          :class="{ 'is-active': tab.isActive }"
        >
          <button class="tab__button" @click="selectTab(tab)" v-html="tab.name"></button>
         </li>
      </ul>
    </div>
    <div class="tab-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    /**
     * Add all the child elements (included via slots) to the `tabs` data attribute
     */
    this.tabs = this.$children;
  },
  methods: {
    /**
     * select clicked tab and unselect the others.
     */
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        /* eslint-disable-next-line no-param-reassign */
        tab.isActive = (selectedTab.name === tab.name);
      });
    },
  },
};
</script>
