<template>
  <div class="event-filter__filters">
    <div class="container">
      <div class="columns">
        <div class="column is-half-tablet is-8-widescreen">
          <p v-if="searchText" class="is-small has-text-blue event-filter__filters__search-text">
            {{ eventCount }} result{{ plural }} for '{{ searchText }}'
          </p>
          <event-filter-pill
            v-if="selectedFiltersCount"
            :filter="{
              optionId: 0,
              optionsName: 'Clear all filters'
            }"
          />
          <event-filter-pill v-for="filter in selectedFilters" :key="filter.id" :filter="filter" />
        </div>
        <div class="column is-3-tablet is-2-widescreen">
          <dropdown-button
            label="Filter results"
            :modal="true"
            :activeFilters="selectedFiltersCount"
            @click="toggleFilterModal"
          />
          <dropdown-modal
            v-if="filterModalActive"
            :filters="filters"
            @toggleModal="toggleFilterModal"
          />
        </div>
        <div class="column is-3-tablet is-2-widescreen">
          <dropdown-button :label="selectedSortOrder.label">
            <dropdown-items :items="[
              {
                id: 0,
                label: 'By date',
              },
              {
                id: 1,
                label: 'A - Z',
              },
              {
                id: 2,
                label: 'Z - A',
              },
            ]"/>
          </dropdown-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import * as queryString from 'query-string';

import DropdownButton from '@/components/events/EventFilter/DropdownButton.vue';
import DropdownItems from '@/components/events/EventFilter/DropdownItems.vue';
import DropdownModal from '@/components/events/EventFilter/DropdownModal.vue';
import EventFilterPill from '@/components/events/EventFilter/EventFilterPill.vue';

export default {
  props: ['page-id'],
  components: {
    DropdownButton,
    DropdownItems,
    DropdownModal,
    EventFilterPill,
  },
  mounted() {
    this.$store.commit('eventFilter/setId', this.pageId);

    // Parse location search string with bracket array format
    // https://github.com/sindresorhus/query-string and passes to get filters

    const parsedQueryString = queryString.parse(window.location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
    });

    this.$store.dispatch('eventFilter/getFilters', parsedQueryString);
  },
  computed: {
    plural() {
      return this.eventCount === 1 ? '' : 's';
    },
    ...mapState({
      filters: state => state.eventFilter.filters,
      filterModalActive: state => state.eventFilter.filterModalActive,
      searchText: state => state.eventFilter.searchText,
      selectedSortOrder: state => state.eventFilter.selectedSortOrder,
    }),
    ...mapGetters({
      eventCount: 'eventFilter/eventsCount',
      selectedFilters: 'eventFilter/selectedFilters',
      selectedFiltersCount: 'eventFilter/selectedFiltersCount',
    }),
  },
  methods: {
    toggleFilterModal() {
      this.$store.commit('eventFilter/toggleFilterModal');
    },
    ...mapActions([
      'eventFilter/getFilters',
    ]),
  },
};
</script>
