<template>
  <article
    v-if="event.display"
    class="card-event"
    :class="{ 'card-event--past': event.inPast }"
    tabindex="0"
  >
    <shortlist-heart
      class="card-event__heart"
      :title="buttonText"
      :event-id="eventId"
      :event-title="event.title"
    ></shortlist-heart>

    <a :href="event.pageUrl" class="card-event__img-link">
      <event-label
        v-if="displayedGenre"
        class="card-event__genre"
        :labelText="displayedGenre"
        labelType="genre"
      />
      <event-label v-if="labelText" class="card-event__label" :labelText="labelText" />
      <img :src="thumbnailUrl" alt="" class="is-block" />
    </a>
    <div class="card-event__details">
      <h6 class="card-event__title">{{ event.title }}</h6>
      <p class="card-event__date">{{ event.dates }}</p>
    </div>
    <div class="card-event__ctas">
      <a class="button is-small is-outlined" title="navigate to event page" :href="event.pageUrl">
        <span>More info</span>
      </a>
      <div class="card-event__modal">
        <buy-tickets
          v-if="event.onSale"
          :event-id="eventId"
          :display-title="true"
          :text="event.ticketButtonText"
          class-name="is-small"
        ></buy-tickets>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios';
import BuyTickets from '@/components/events/BuyTickets.vue';
import EventLabel from '@/components/events/EventLabel.vue';
import ShortlistHeart from '@/components/events/ShortlistHeart.vue';

export default {
  name: 'event-card',
  components: {
    BuyTickets,
    EventLabel,
    ShortlistHeart,
  },
  props: {
    customLabel: String,
    dates: String,
    display: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
    genre: Array,
    image: Object,
    inPast: {
      type: Boolean,
      default: false,
    },
    label: String,
    onSale: {
      type: Boolean,
      default: true,
    },
    pageUrl: String,
    ticketButtonText: String,
    title: String,
  },
  data() {
    return {
      event: {
        customLabel: '',
        dates: '',
        display: true,
        genre: [],
        image: '',
        inPast: false,
        label: '',
        onSale: true,
        pageUrl: '',
        ticketButtonText: '',
        title: '',
      },
    };
  },
  computed: {
    buttonText() {
      return `Add ${this.event.title} to events shortlist`;
    },
    displayedGenre() {
      if (this.event.genre.length > 0) {
        return this.event.genre[0].name;
      }
      return '';
    },
    labelText() {
      if (this.event.inPast) return 'Past Event';
      if (this.event.customLabel) return this.event.customLabel;
      if (this.event.label) return this.event.label;
      return null;
    },
    thumbnailUrl() {
      if (this.event.image) {
        return this.event.image.url;
      }
      return null;
    },
  },
  mounted() {
    if (this.title) {
      this.event.customLabel = this.customLabel;
      this.event.dates = this.dates;
      this.event.genre = this.genre;
      this.event.image = this.image;
      this.event.inPast = this.inPast;
      this.event.label = this.label;
      this.event.onSale = this.onSale;
      this.event.pageUrl = this.pageUrl;
      this.event.ticketButtonText = this.ticketButtonText;
      this.event.title = this.title;
    } else {
      this.getData();
    }
  },
  methods: {
    /**
     * get data from api call
     */
    getData() {
      axios.get(`${process.env.VUE_APP_API_URL}pages/${this.eventId}/`)
        .then((response) => {
          this.event.customLabel = response.data.custom_label;
          this.event.dates = response.data.date_range;
          this.event.genre = response.data.genre;
          this.event.image = response.data.thumbnail;
          this.event.inPast = response.data.in_past;
          this.event.label = response.data.label;
          this.event.onSale = response.data.on_sale;
          this.event.pageUrl = response.data.url;
          this.event.ticketButtonText = response.data.ticket_button_text;
          this.event.title = response.data.title;
        })
        .catch(() => {
          this.event.display = false;
        });
    },
  },
};
</script>
