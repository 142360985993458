<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 14 14" >
    <!-- eslint-disable-next-line max-len -->
    <g transform="translate(0 8.37)"><path d="M13.77.225h-1.482a.23.23 0 0 0-.23.231v3.201H1.941V.456a.23.23 0 0 0-.23-.231H.23A.231.231 0 0 0 0 .456v4.913c0 .128.104.231.23.231H13.77a.23.23 0 0 0 .23-.231V.456a.23.23 0 0 0-.23-.231" fill="#3A37CC"/></g><path d="M9.962 5.984h-2V.25a.25.25 0 0 0-.25-.25H6.288a.25.25 0 0 0-.25.25v5.734h-2a.348.348 0 0 0-.266.573l2.961 3.527c.14.166.395.166.534 0l2.961-3.527a.348.348 0 0 0-.266-.573" fill="#3A37CC"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-download',
};
</script>
