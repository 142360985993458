<template>
  <button
    class="nav-icons__item"
    title="search site"
    aria-controls="nav-search-bar"
    @click="toggleSearch"
  >
    <span class="icon icon--search" aria-hidden="true"><svg-search></svg-search></span>
  </button>
</template>

<script>
import SvgSearch from '@/svgs/Search.vue';

export default {
  name: 'search-button',
  components: { SvgSearch },
  methods: {
    /**
     * Toggle search visibility
     */
    toggleSearch() {
      this.$store.commit('menu/toggleSearch');
    },
  },
};
</script>
