<template>
  <div class="form-item">
    <div class="form-dropdown">
      <button
        v-click-outside="hideExpanded"
        class="form-dropdown__toggle"
        :class="{ 'is-active' : expandedNoModal }"
        title="expand multiselect label options"
        :aria-expanded="ariaExpanded"
        aria-labelledby="multiselect-label"
        aria-describedby="multiselect-label-alert"
        aria-controls="multiselect-options"
        @click="toggleExpand"
      >
        <span class="form-dropdown__items">
          <span>{{ label }}</span>
          <span v-if="activeFilters" class="form-dropdown__number">{{ activeFilters }}</span>
        </span>
      </button>
      <fieldset v-if="expandedNoModal" class="form-dropdown__fieldset">
        <slot />
      </fieldset>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  props: {
    label: {
      type: String,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    activeFilters: {
      type: Number,
    },
  },
  computed: {
    ariaExpanded() {
      return this.expanded ? 'true' : 'false';
    },
    expandedNoModal() {
      return this.expanded && !this.modal;
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
      this.$emit('click');
    },
    hideExpanded() {
      this.expanded = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
