<template>
  <button
    class="nav-icons__item nav-icons__item--menu"
    :class="{ 'is-active ': active }"
    title="toggle menu"
    aria-controls="main-menu"
    :aria-expanded="active.toString()"
    @click="toggle">
    <span
      class="nav-icons__label nav-icons__label--open is-family-monospace is-meta is-hidden-touch"
      :class="{ 'is-hidden': active }"
      :aria-hidden="active.toString()">Menu</span>
    <span
      class="nav-icons__label nav-icons__label--close is-family-monospace is-meta is-hidden-touch"
      :class="{ 'is-hidden': !active }"
      :aria-hidden="(!active).toString()">Close</span>
    <div class="nav-icons__hamburger" :aria-hidden="active.toString()">
      <span class="hamburger" :aria-hidden="active.toString()"></span>
    </div>
  </button>
</template>

<script>
import Vue from 'vue';
// eslint-disable-next-line
import { mapState } from 'vuex';

export default {
  name: 'burger',
  computed: {
    ...mapState({
      active: state => state.menu.active,
    }),
  },
  methods: {
    toggle() {
      if (this.active) {
        this.$store.commit('menu/setMenuActive', false);
      } else {
        this.$store.commit('menu/setMenuVisible', true);
        Vue.nextTick(() => {
          this.$store.commit('menu/setMenuActive', true);
        });
      }
    },
  },
};
</script>
